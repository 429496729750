import Base from "../services/baseClientService";
import store from "../redux/store";
import A from "../redux/actionTypes";

class UserService extends Base {
  static async getUsers() {
    try {
      const users = await this.GET(`/organization/users`);

      return users;
    } catch (e) {
      console.error("Error fetching organization users: ", e.message);
      this.Error(e.message);
      return false;
    }
  }

  static async getStudents() {
    const users = await this.getUsers();
    if (!users) return false;

    if (!Array.isArray(users))
      return this.Error(
        "Unknown error while getting students. Please try reloading the page"
      );

    // filter out only students
    return users.filter((u) => u.credentials.includes("student"));
  }

  static async updateOrganizationUser(data, id) {
    try {
      if (id) {
        data = { ...data, id };
      }

      //update user in server
      const users = await this.POST(`/organization/users/update`, data);
      const reduxUser = store.getState().auth.user;
      //checking if user is the redux user
      if (reduxUser.id === id) {
        //updating redux with updated user
        store.dispatch({
          type: A.UPDATE_USER,
          payload: {
            userData:{
              ...reduxUser,
              ...data
            }
          },
        });
      }
      return users;
    } catch (e) {
      console.error("Error fetching organization users: ", e.message);
      this.Error(e.message);
      return false;
    }
  }

  /**
   * Updates user image
   * @param {*} id
   * @param {*} image
   * @returns
   */
  static async updateUserImage(id, formData) {
    try {

      await this.POST(`/organization/users/${parseInt(id)}/image`, formData);

      const reduxUser = store.getState().auth.user;
      //checking if user is the redux user
      const user = ((await this.getUsers()) || []).find(user => user?.id === id);
      if (user && reduxUser.id === id) {
        //updating redux with updated user
        store.dispatch({
          type: A.UPDATE_USER,
          payload: {
            userData:{
              ...reduxUser,
              image: user.image,
            }
          },
        });
      }
      return true;
    } catch (e) {
      console.error("Error updating user image:", e.message);
      return this.Error(e.message);
    }
  }

  static async updateOrganizationUserImage(formData, id) {
    try {
      const users = await this.POST(`organization/users/${id}/image`, formData);

      return users;
    } catch (e) {
      console.error("Error fetching organization users: ", e.message);
      this.Error(e.message);
      return false;
    }
  }

  static async deleteOrganizationUser(id) {
    try {
      const result = await this.POST(`/organization/users/${id}/delete`);

      return result;
    } catch (e) {
      console.error("Error deleting organization user: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
  static async deleteStudents( ids ) {
    try {
      const result = await this.POST(`/organization/users/delete`,{ids});

      return result;
    } catch (e) {
      console.error("Error deleting organization user: ", e.message);
      this.Error(e.message);
      return false;
    }
  }

  /**
   * newStatus has to be: activate/suspend/archive
   * @param {Number} user_id
   * @param {String} newStatus
   * @returns
   */
  static async changeUserStatus(user_id, newStatus) {
    try {
      const result = await this.POST(
        `/organization/users/${user_id}/${newStatus}`
      );

      return result;
    } catch (e) {
      console.error("Error changing organization user status: ", e.message);
      this.Error(e.message);
      return false;
    }
  }
}

export default UserService;
