import Actions from "./actions";
import A from "./actionTypes";
const localStorageKey = "SESOrgAuth";

let localStorageState;

try {
  localStorageState = localStorage.getItem(localStorageKey)
    ? JSON.parse(localStorage.getItem(localStorageKey))
    : {};
} catch (e) {
  localStorageState = {};
}

const initialState = {
  ...{
    user: null,
    authorization: null,
    lastUsed: null,
    tokenTimestamp: Date.now(),
    language: "en",
  },
  // overwrite values from local state, if any
  ...localStorageState,
};

export default function root(state = initialState, action) {
  let newState = { ...state };
  const { type, payload } = action;
  let now = Date.now();

  switch (type) {
    // case A.LOGIN:
    //   const { token, user } = payload;
    //   // put token into state as authorization header value
    //   newState = { ...state, user, authorization: `Bearer ${token}` };
    //   break;

    // case A.LOGOUT:
    //   newState = { ...state, authorization: null, user: null };
    //   break;

    case A.LOGIN:
      const { token, user } = payload;
      if (token && user) {
        // put token into state as authorization header value
        newState = {
          ...state,
          language: user.interface_language,
          user,
          authorization: `Bearer ${token}`,
          tokenTimestamp: now,
        };
        Actions.onTokenUpdate(now);
      }
      break;

    case A.UPDATE_USER:
      const { userData } = payload;
      if (userData) {
        // put token into state as authorization header value
        newState = {
          ...state,
          user: { ...userData },
        };
      }
      break;

    case A.LOGOUT:
      newState = { ...state, authorization: null, user: null };
      Actions.clearTokenUpdateTimer();
      break;

    case A.RENEW_TOKEN:
      newState = {
        ...state,
        authorization: `Bearer ${payload}`,
        tokenTimestamp: now,
      };
      Actions.onTokenUpdate(now);
      break;

    case A.SET_USAGE_TIMESTAMP:
      console.debug("setting usage timestamp to", now);
      try {
        newState = { ...state, lastUsed: now };
      } catch (e) {
        console.error(e.message);
      }
      break;

    case A.SET_LANG: {
      let { user } = newState;

      // update language also in user object
      if (user) {
        user = { ...user, interface_language: payload };
      }

      console.debug("Redux: set language to", payload);
      newState = { ...newState, language: payload, user };
      break;
    }
    default:
    //newState = state;
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newState));

  return newState;
}
