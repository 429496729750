import { Grid, Typography, Button } from "@material-ui/core";
import {
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import React from "react";
import { SaveAlt, FilterList } from "@material-ui/icons";
import OrganizationService from "../../../../services/OrganizationService";
import AsyncButton from "../../../template/AsyncButton";
import ProgramService from "../../../../services/ProgramService";
import Spinner from "../../../template/Spinner";
import Error from "../../../template/Error";
import { Redirect } from "react-router-dom";
import "./report-programs.scss";
import WithTranslations from "../../../WithTranslations";
import LocalizedDataGrid from "../../../template/localized-Data-Grid";
import FilterForm from "../../../template/filter-form";

const CustomToolbar = ({ children }) => (
  <GridToolbarContainer className="admin-table-toolbar">
    {children}
  </GridToolbarContainer>
);

class ReportPrograms extends React.Component {
  state = {
    studentPrograms: [
      // {
      //     title: "Test program 3",
      //     columns: []
      //     rows:[{full_name:"test",course_title:grade,...,final_grade:grade}]
      // },
    ],
    error: null,
    onfilter: null,
    fields: [],
    originalfilters: null,
  };
  componentDidMount() {
    this.fetchPrograms();
    this.props.fetchTranslations([
      "Student name",
      "Final Grade",
      "Programs Report",
      "export To Excel",
      "filter",
      "Programs selected:",
      "Select programs",
    ]);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.students !== this.props.students) {
      this.fetchPrograms();
    }
  }
  async fetchPrograms() {
    const { onShowMessage } = this.props;
    const programs = await ProgramService.getPrograms();
    if (!programs) {
      this.setState({
        error: ProgramService.error || `Unknown error fetching programs`,
      });
      return;
    }
    //fetch all the organization programs and then ready the report data
    this.setState({ programs }, this.populatestudentPrograms);
  }
  populatestudentPrograms = () => {
    let studentPrograms = [];
    let options = {};
    const { _t } = this.props;
    const columns_template = [
      {
        field: "full_name",
        headerName: _t("Student name"),
        sortable: false,
        width: 250,
      },
      {
        field: "final_grade",
        headerName: _t("Final Grade"),
        sortable: false,
        width: 150,
      },
    ];
    const { students, studentsprogress } = this.props;
    const { programs } = this.state;
    console.log("populatestudentPrograms", students, studentsprogress);
    if (
      Array.isArray(programs) &&
      Array.isArray(students) &&
      Array.isArray(studentsprogress)
    ) {
      students.map((student) => {
        if (Array.isArray(student.programs)) {
          student.programs.map((program) => {
            const current_program = programs.find(
              (p) => p.program_id === program.program_id
            );
            let program_columns = [
              ...columns_template,
              ...(Array.isArray(current_program.courses)
                ? current_program.courses
                    .sort((a, b) => a.course_code.localeCompare(b.course_code))
                    .map((course) => {
                      return {
                        field: course.course_code, // course.title,
                        headerName: course.title,
                        sortable: false,
                        width: 150,
                      };
                    })
                : []),
            ];
            // if (
            //   studentPrograms[program.title] &&
            //   studentPrograms[program.title].columns &&
            //   Array.isArray(studentPrograms[program.title].columns)
            // ) {
            //   program_columns = [...studentPrograms[program.title].columns];
            // } else {
            //   program_columns = [...columns_template];
            // }
            let program_rows = [];
            if (
              studentPrograms[program.title] &&
              studentPrograms[program.title].rows &&
              Array.isArray(studentPrograms[program.title].rows)
            ) {
              program_rows = [...studentPrograms[program.title].rows];
            }
            let program_row = { id: student.id, full_name: student.full_name };
            studentsprogress
              .filter((s) => s.user_id === student.id)
              .map((st) => {
                st.course_progress?.map((course) => {
                  const course_grade =
                    course.total_score && Math.round(course.total_score);
                  // const course_grades = course.progress
                  //   .map((p) => p.experiment_grade)
                  //   .filter((g) => g);
                  // const course_grade = course_grades
                  //   ? Math.round(
                  //       course_grades.reduce((a, b) => a + b, 0) /
                  //         course_grades.length
                  //     )
                  //   : null;
                  // program_columns = [
                  //   ...program_columns,
                  //   {
                  //     field: `${course.title}`,
                  //     headerName: `${course.title}`,
                  //     sortable: false,
                  //     width: 150,
                  //   },
                  // ];
                  program_row[course.course_code] = `${
                    course_grade
                      ? course_grade
                      : `${course.progress_percent || 0}%`
                  }`;
                });
              });
            let final_grade = 0;
            let count = 0;
            Array.isArray(current_program.courses) &&
              current_program.courses.map((course) => {
                if (
                  !program_row[course.course_code]?.includes("%") &&
                  parseInt(program_row[course.course_code])
                ) {
                  final_grade += parseInt(program_row[course.course_code]);
                  count++;
                }
                if (!program_row[course.course_code])
                  program_row[course.course_code] = "0%";
              });
            console.log("test", count, final_grade);
            program_row["final_grade"] = `${
              final_grade / count && count == current_program.courses.length
                ? Math.round(final_grade / count)
                : ""
            }`;
            program_rows = [...program_rows, program_row];
            const program_index = studentPrograms.findIndex(
              (p) => p.title === program.title
            );
            if (program_index === -1) {
              studentPrograms = [
                ...studentPrograms,
                {
                  id: program.program_id,
                  title: program.title,
                  columns: program_columns || [],
                  rows: program_rows || [],
                },
              ];
            } else if (program_index > -1) {
              studentPrograms[program_index] = {
                id: program.program_id,
                title: program.title,
                columns: program_columns || [],
                rows: [
                  ...studentPrograms[program_index].rows,
                  ...(program_rows || []),
                ],
              };
            }
            options[program.program_id] = program.title;
          });
        }
      });
      this.setState({
        OriginalstudentPrograms: [...studentPrograms],
        studentPrograms,
        fields: [
          ...this.state.fields,
          {
            id: "programs",
            label: _t("Select programs"),
            type: "chips",
            //fieldsToCompare: [ "status"],
            options: options,
            value: Object.keys(options),
          },
        ],
        originalfilters: [
          ...(this.state.originalfilters || []),
          {
            id: "programs",
            value: Object.keys(options),
          },
        ],
      });
    }
    //console.log("ReportPrograms",studentPrograms);
  };
  fetchReport = async (title) => {
    const { fields, programs, originalfilters } = this.state;
    const changedFilters =
      Array.isArray(fields) &&
      Array.isArray(originalfilters) &&
      !fields.reduce(
        (res, field) =>
          Boolean(
            originalfilters.find(
              (f) =>
                f.id === field.id &&
                JSON.stringify(f.value.sort()) ===
                  JSON.stringify(field.value.sort())
            )
          ) && res,
        true
      );
    let { studentPrograms } = this.state;
    const { _t } = this.props;
    if (title) {
      studentPrograms = [
        {
          ...studentPrograms.find((p) => p.title === title),
        },
      ];
    }
    const data = {
      filter: (changedFilters && _t("Programs Report") + " " +_t("Programs selected:") +
        " " +
        fields
          .find((field) => field.id === "programs")
          .value.map(
            (p) =>
              programs.find(
                (program) => program.program_id === parseInt(p)
              ).title
          )
          .join(", ")),
      studentPrograms,
    };
    const result = await OrganizationService.programsReportExcel(data);
    const { onShowMessage } = this.props;
    if (!result) {
      onShowMessage(
        OrganizationService.error || `Unknown error fetching report`,
        "error"
      );
      return false;
    }
    const ab = new ArrayBuffer(result.data.length);
    const view = new Uint8Array(ab);
    for (let i = 0; i < result.data.length; ++i) {
      view[i] = result.data[i];
    }
    //console.log(ab,result.data[0]);
    const blob = new Blob([ab]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      title ? `${title.toUpperCase()}_REPORT.xlsx` : "PROGRAM_REPORT.xlsx"
    );
    link.click();
    window.URL.revokeObjectURL(url);
    console.log("your file has downloaded!");
  };
  filterAll = (data) => {
    let { OriginalstudentPrograms, fields } = this.state;
    // update fields
    fields = fields.map((field) => {
      if (field.id in data) {
        field.value = data[field.id];
      }
      return field;
    });
    console.log("filterAll", data, OriginalstudentPrograms);
    // filter programs
    this.setState({
      fields,
      studentPrograms: OriginalstudentPrograms.filter((p) =>
        data["programs"].includes(`${p.id}`)
      ),
    });
  };
  render() {
    const {
      students,
      filters,
      groups,
      studentsprogress,
      report,
      _t,
      onShowMessage,
    } = this.props;
    const {
      studentPrograms,
      error,
      onfilter,
      fields,
      originalfilters,
      programs,
    } = this.state;
    const changedFilters =
      Array.isArray(fields) &&
      Array.isArray(originalfilters) &&
      !fields.reduce(
        (res, field) =>
          Boolean(
            originalfilters.find(
              (f) =>
                f.id === field.id &&
                JSON.stringify(f.value.sort()) ===
                  JSON.stringify(field.value.sort())
            )
          ) && res,
        true
      );
    //console.log("ReportPrograms",students,filters,groups,studentsprogress);
    console.log("ReportPrograms", studentPrograms);
    if (report && !error && !(students && studentsprogress)) {
      return <Redirect to={"/reports"} />;
    }
    return (
      <div class="report-programs">
        {error && <Error error={error} />}
        {!error && !studentPrograms && <Spinner />}
        {!error && studentPrograms && (
          <>
            <Typography
              color="primary"
              variant="h1"
              class="flex justify-content-space-between"
            >
              {_t("Programs Report")}
              <div className="right-side">
                <Button
                  variant="contained"
                  // className="add-button bg-orange color-white"
                  color="primary"
                  {...{ className: changedFilters && "red-indicator" }}
                  onClick={(e) => this.setState({ onfilter: e.target })}
                >
                  {<FilterList />}
                  {_t("filter")}
                </Button>
                <AsyncButton
                  variant="contained"
                  // className="add-button bg-orange color-white"
                  color="primary"
                  onClick={() => this.fetchReport()}
                  icon={<SaveAlt />}
                >
                  {_t("export To Excel")}
                </AsyncButton>
              </div>
            </Typography>
            {changedFilters && (
              <div>
                {_t("Programs selected:") +
                  " " +
                  fields
                    .find((field) => field.id === "programs")
                    .value.map(
                      (p) =>
                        programs.find(
                          (program) => program.program_id === parseInt(p)
                        ).title
                    )
                    .join(", ")}
              </div>
            )}
            {Array.isArray(studentPrograms) &&
              studentPrograms.map((program) => {
                const { title, columns, rows } = program;
                //console.log("ReportPrograms Object.entries",program_title,columns,rows);
                return (
                  <div class="program-user-grades">
                    <div class="program-title">
                      <Typography variant="h2" color="primary">
                        {title}
                      </Typography>
                    </div>
                    <div className="program-data bubble stretch">
                      {Array.isArray(columns) && Array.isArray(rows) && (
                        <LocalizedDataGrid
                          loading={!Boolean(rows)}
                          {...{ columns, rows }}
                          pageSize={7}
                          autoHeight
                          disableSelectionOnClick
                          components={{
                            Toolbar: () => (
                              <AsyncButton
                                variant="outlined"
                                // className="add-button bg-orange color-white"
                                color="primary"
                                size="small"
                                onClick={() => this.fetchReport(title)}
                                icon={<SaveAlt />}
                              >
                                {_t("export To Excel")}
                              </AsyncButton>
                            ),
                          }}
                          hideFooterPagination={true}
                          disableColumnMenu={true}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </>
        )}
        {onfilter && (
          <FilterForm
            anchorEl={onfilter}
            fields={fields}
            onCancel={() => this.setState({ onfilter: null })}
            onUpdate={(filter) => {
              this.filterAll(filter);
              this.setState({ onfilter: null });
            }}
            // pass a way to show error messages
            showMessage={onShowMessage}
          />
        )}
      </div>
    );
  }
}

export default ReportPrograms;
