import pipeline from "@bit/ses-education.ses-courses-shared-components.parser/Utils/pipeline";
import { Typography } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import React from "react";
import { fixUrl } from "../../../../../utils/functions";

/**
 * A component to "pipeline" a question text (replace special character sequences)
 * @param {string|null|undefined} text
 * @returns
 */
const Pipe = ({ text = "" }) => (
  <span dangerouslySetInnerHTML={{ __html: pipeline(text) }}></span>
);

const ReportCourseChapter = (props) => {
  const { _t, ...c } = props;

  const noDataMessage = <span className="no-data">{_t("No data found")}</span>;

  return (
    <div className="chapter">
      <Typography variant="h4">{c.title}</Typography>
      <div className="questions">
        {/* <span style={{ position: "absolute" }}>
          {c?.answers?.length} questions
        </span> */}
        {Array.isArray(c.answers) &&
          c.answers
            .filter((ans) => !ans.internal_question_id)
            .map((quiz) => {
              // console.debug("QUIZ:\n", quiz);
              // show "no data found" message if empty
              if (
                !Array.isArray(quiz.question_data.questions) ||
                quiz.question_data.questions.length === 0
              ) {
                return noDataMessage;
              }
              return quiz.question_data.questions.map((ans) => {
                const { id, title, qimage, answers, rightAnswerId } = ans;
                return (
                  <div className="question">
                    <Typography variant="body1" color="primary">
                      <Pipe text={title} />
                    </Typography>
                    {qimage && qimage.value && qimage.value !== "" && (
                      <img
                        alt={qimage.meta.title}
                        src={fixUrl(qimage.value, true)}
                      />
                    )}
                    <table>
                      <tbody>
                        {(!Array.isArray(answers) || answers.length === 0) && (
                          <tr>
                            <td>{noDataMessage}</td>
                          </tr>
                        )}
                        {Array.isArray(answers) &&
                          answers.map((answer) => {
                            const answers_list =
                              c.answers.filter(
                                (a) => a.internal_question_id === id
                              ) || [];
                            const answer_count = (
                              answers_list.filter(
                                (a) => a.selected_answer === answer.id
                              ) || []
                            ).length;

                            const icon =
                              rightAnswerId === answer.id ? (
                                <Check />
                              ) : (
                                <Close className="color-red" />
                              );
                            return (
                              <tr
                                className={
                                  rightAnswerId === answer.id
                                    ? "color-green"
                                    : ""
                                }
                              >
                                <td>
                                  {icon}
                                  <Pipe text={answer.text} />
                                  {answer.aimage &&
                                    answer.aimage.value &&
                                    answer.aimage.value !== "" && (
                                      <img
                                        src={fixUrl(answer.aimage.value, true)}
                                      />
                                    )}
                                  {/* {pipeline(answer.text)} */}
                                </td>
                                <td>{answer_count}</td>
                                <td>
                                  <span>
                                    {
                                      // treat edge case of empty answer list
                                      answers_list.length > 0
                                        ? // round to integer
                                          Math.round(
                                            (answer_count /
                                              answers_list.length) *
                                              100
                                          )
                                        : 0
                                    }
                                    %{" "}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                );
              });
            })}
        {(!Array.isArray(c.answers) || c.answers.length === 0) && noDataMessage}
      </div>
    </div>
  );
};

export default ReportCourseChapter;
