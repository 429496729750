import { Button } from "@material-ui/core";
import { DoneAll, Refresh } from "@material-ui/icons";
import React from "react";
import SelectList from ".";

/**
 * Extends the SelectedList component by storing a list of selected items and passing it to onChange callback function when the list changes
 */
class SelectListExtended extends React.Component {
  state = {
    selectedItems: [],
    error: null,
  };

  componentDidMount() {
    this.updateSelectedItems();
  }

  updateSelectedItems() {
    let { selectedItems = [] } = this.props;
    if (!Array.isArray(selectedItems)) {
      return this.setState({ error: "Error in data" });
    }

    this.setState({ selectedItems });
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.selectedItems) !==
      JSON.stringify(this.props.selectedItems)
    ) {
      this.updateSelectedItems();
    }
  }

  onSearch = ([key, value], search) => {
    key = (key + "").toLowerCase(); // just in case cast to string
    value = value.toLowerCase();
    search = search.toLowerCase();
    console.debug("searching for", search, "in", key, ",", value);
    return key.includes(search) || value.includes(search);
  };

  onSelect = ([key, value]) => {
    console.debug("selected", key, value);
    let { selectedItems } = this.state;

    const { onChange = console.debug } = this.props;

    // update selected items
    selectedItems =
      // if the key already exists -...
      selectedItems.includes(key)
        ? // ...remove the existing key
          selectedItems.filter((i) => i !== key)
        : // ..otherwise - add the key
          [...selectedItems, key];

    // update state and pass the updated list to callback
    this.setState({ selectedItems }, () => onChange(selectedItems));
  };

  render() {
    const {
      items = {},
      onChange = console.debug,
      onSearch,
      ItemRenderer: OriginalRenderer = ({ title }) => <>{title || "???"}</>,
      clearSelectionText = "Clear selection",
      selectAllText = "Select all",
      afterSearch = <></>,
      selected = [],
      ...other
    } = this.props;

    // console.debug("Extended list items: ", items);

    const { selectedItems, error } = this.state;
    if (error) return <div className="error-message">{error}</div>;
    return (
      <div className="extended-list">
        <SelectList
          {...other}
          // add clear button
          afterSearch={
            <>
              {afterSearch}
              {selectedItems.length > 0 && (
                <Button
                  title={clearSelectionText}
                  className="clear-list"
                  onClick={() => this.setState({ selectedItems: [] }, () => onChange(this.state.selectedItems))}
                >
                  <Refresh />
                </Button>
              )}
              {items && Object.keys(items).length > 0&& (
                <Button
                  title={selectAllText}
                  className="clear-list"
                  onClick={() => this.setState(
                    { selectedItems: Object.keys(items) },
                    () => onChange(this.state.selectedItems)
                  )}
                >
                  <DoneAll />
                </Button>
              )}
            </>
          }
          items={Object.values(items).map((title, index) => [
            Object.keys(items)[index],
            title,
            index,
          ])}
          onItemClick={this.onSelect}
          onSearch={onSearch || this.onSearch}
          ItemRenderer={(data) => {
            // console.debug("item data:", data);
            const { item } = data;
            if (!Array.isArray(item)) {
              console.debug("Item not iterable!", item);
              return "Item not iterable";
            }
            const [key, title] = item;
            return (
              <span
                className={`itm${
                  selectedItems.includes(key) ? " selected" : ""
                }`}
              >
                <OriginalRenderer {...{ key, title, item }} />
              </span>
            );
          }}
        />
      </div>
    );
  }
}

export default SelectListExtended;
