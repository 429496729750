import React from "react";
import "./style.scss";
import UserService from "../../../../services/UserService";
import { showMessage } from "../../../../redux/notificationActions";
import { connect } from "react-redux";
import {
  Typography,
  Tooltip,
  Zoom,
  ButtonGroup,
  Button,
  Select,
  TextField,
  FormControlLabel,
  Switch,
  FormGroup,
  MenuItem,
} from "@material-ui/core";
import ModalWindow from "@bit/ses-education.ses-courses-shared-components.modal-window";
import OrganizationService from "../../../../services/OrganizationService";
import { Link } from "react-router-dom";
import ReportStudents from "../reports/report-students";
import ReportPrograms from "../reports/report-programs";
import ReportStudentsExtended from "./reports/report-students-extended";
import { Description, Check } from "@material-ui/icons";
import DebounceSearch from "../../../template/DebounceSearch";
import Page404 from "../../../template/Page404";
import LocalizedDataGrid from "../../../template/localized-Data-Grid";
import WithTranslations from "../../../WithTranslations";
import ReportCourses from "../reports/report-courses";
import SelectList from "../../../template/SelectList";
import SelectListExtended from "../../../template/SelectList/select-list-extended";
const filterFunctions = {
  group: (value) => (row) => {
    return row.groups.includes(value);
  },
  status: (value) => (row) => {
    return row.status.status_title === value;
  },
  search: (value) => (row) => {
    return (
      (row.full_name &&
        row.full_name.toLowerCase().includes(value.toLowerCase())) ||
      (row.email && row.email.toLowerCase().includes(value.toLowerCase())) ||
      (row.citizen_id &&
        row.citizen_id.toLowerCase().includes(value.toLowerCase()))
    );
  },
  selectedOnly: (value) => (row) => {
    if (value.on) {
      return value.items.includes(row.id);
    } else {
      return true;
    }
  },
};

class ReportsStudentsList extends React.Component {
  state = {
    students: null,
    filters: {
      group: -1,
      status: -1,
      selectedOnly: {
        on: false,
        items: [],
      },
    },
    statusList: null,
    allStudents: null,
    groups: null,
    studentsprogress: null,
    studentsprogress_filtered: null,
    selectCourseModel: false,
    courses: null,
    selectedCourses: null,
  };
  async componentDidMount() {
    // showMessage("enter report list render", "info");
    this.fetchStatusList();
    await this.fetchUsers();
    await this.fetchStudentsProgress();
    this.props.fetchTranslations([
      "ID",
      "Name",
      "Email",
      "Progress",
      "Status",
      "Groups",
      "Show selected only",
      "Search",
      "Grades Report",
      "Extended Report",
      "Program Report",
      "Course Report",
      "Select all",
      "All",
      "Select courses for the report",
    ]);
    // take first object with class MuiDataGrid-columnHeaderWrapper
    const wrap = document.getElementsByClassName(
      "MuiDataGrid-columnHeaderWrapper"
    )[0];
    const obj = wrap && wrap.querySelectorAll('[data-field="__check__"]')[0];
    // take first object with data-field="__check__" attribute and add an checkbox-text attribute to it
    obj && obj.setAttribute("checkbox-text", this.props._t("Select all"));
  }
  async componentDidUpdate(prevProps) {
    // take first object with class MuiDataGrid-columnHeaderWrapper
    const wrap = document.getElementsByClassName(
      "MuiDataGrid-columnHeaderWrapper"
    )[0];
    const obj = wrap && wrap.querySelectorAll('[data-field="__check__"]')[0];
    if (prevProps.report !== this.props.report && !this.props.report) {
      this.setState({
        students: null,
        filters: {
          group: -1,
          status: -1,
          selectedOnly: {
            on: false,
            items: [],
            students: [],
          },
        },
        statusList: null,
        allStudents: null,
        groups: null,
        studentsprogress: null,
        studentsprogress_filtered: null,
        selectCourseModel: false,
        courses: null,
        selectedCourses: null,
      });
      this.fetchStatusList();
      await this.fetchUsers();
      await this.fetchStudentsProgress();
    }
    if (
      obj &&
      obj.getAttribute("checkbox-text") != this.props.translations["Select all"]
    ) {
      // take first object with data-field="__check__" attribute and add an checkbox-text attribute to it
      obj.setAttribute("checkbox-text", this.props._t("Select all"));
    }
  }
  async fetchStatusList() {
    const statusList = await OrganizationService.getStatusList();
    this.setState({ statusList }, () =>
      this.props.fetchTranslations(statusList.map((i) => i.status_title))
    );
  }
  fetchCourses = () => {
    const { students } = this.state.filters.selectedOnly;
    let courses = [];
    if (Array.isArray(students)) {
      students.map((st) => {
        if (Array.isArray(st.assigned_courses)) {
          st.assigned_courses.map((c) => {
            const { course_id, title, course_code } = c;
            // check if course already exists in courses array from previous students
            if (!courses.find((c) => c.course_id == course_id)) {
              courses = [
                ...courses,
                {
                  course_id,
                  title,
                  course_code,
                },
              ];
            }
          });
        }
      });
    }
    this.setState({ courses });
  };
  async fetchUsers() {
    try {
      console.log("fetchUsers");
      let users = await UserService.getUsers();

      if (Array.isArray(users)) {
        // select STUDENT users that are not currently in group
        const groups = await this.fetchGroups();
        const students = users
          .filter(
            (user) => user.credentials && user.credentials.includes("student")
          )
          .map((u) => {
            let group = groups
              .filter((g) => g.students.find((s) => s.id === u.id))
              .map((g) => g.student_group_id);
            return {
              ...u,
              id: u.id,
              user_id: u.id,
              groups: group,
              citizen_id: u.citizen_id,
              full_name: `${u.name}${u.last_name ? ` ${u.last_name}` : ""}`,
              progress: [
                u.courses_done && u.courses_total && u.course_progress ? (
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={this.populateProgressDetails(u.course_progress)}
                    arrow
                  >
                    <Typography style={{ cursor: "default" }}>
                      {u.courses_done + "/" + u.courses_total}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography style={{ cursor: "default" }}>{"N/A"}</Typography>
                ),
              ],
            };
          });
        this.setState({ students, allStudents: students, groups });
        console.log("setState", this.state);
        return true;
      } else {
        console.log("Failed loading users");
        return false;
      }
    } catch (err) {
      console.error(err);
      //this.props.onShowMessage(`Fetching users from server failed.`, "error");
      return false;
    }
  }
  async fetchGroups() {
    let groups = await OrganizationService.getStudentGroups();
    const error = groups ? null : OrganizationService.error || "Unknown error";
    if (!error && groups) {
      console.log("fetchGroups", groups);
      this.setState({ groups });
      return groups;
    } else {
      console.error("fetchGroups:", error);
    }
  }
  async fetchStudentsProgress() {
    const { allStudents } = this.state;
    let studentsprogress = await OrganizationService.getStudentsProgress();
    const error = studentsprogress
      ? null
      : OrganizationService.error || "Unknown error";
    if (!error && studentsprogress) {
      //add citizen_id to students progress
      studentsprogress = studentsprogress.map((sp) => {
        return {
          ...sp,
          citizen_id: (
            (allStudents || []).find((s) => s.id === sp.user_id) || {}
          ).citizen_id,
        };
      });
      console.log("fetchStudentsProgress", studentsprogress);
      this.setState({
        studentsprogress,
        studentsprogress_filtered: studentsprogress,
      });
    } else {
      console.error("fetchStudentsProgress:", error);
    }
  }

  populateProgressDetails(course_progress) {
    return (
      <Typography>
        {Array.isArray(course_progress) &&
          course_progress.map((item) => {
            return (
              <ul className="tooltip-list">
                <li>
                  <span>{item.course_code}</span>
                  <span>&nbsp;</span>
                  <span>{item.progress + "%"}</span>
                </li>
              </ul>
            );
          })}
      </Typography>
    );
  }
  onSelect = (model) => {
    const { allStudents } = this.state;
    let filters = { ...this.state.filters };
    filters.selectedOnly.items = model;
    //console.log("onSelect", model, filters);
    filters.selectedOnly.students = allStudents.filter((s) =>
      model.includes(s.id)
    );
    let studentsprogress = [...(this.state.studentsprogress || [])];
    studentsprogress = studentsprogress.filter((sp) =>
      filters.selectedOnly.students.map((u) => u.user_id).includes(sp.user_id)
    );
    this.setState(
      { filters, studentsprogress_filtered: studentsprogress },
      this.filterEverything
    );
  };
  onSelect2 = (event, type) => {
    let { filters } = this.state;
    filters = { ...filters, [type]: event.target.value };
    this.setState({ filters }, this.filterEverything);
  };

  filterEverything = () => {
    console.log("filterEverything", this.state);
    const { filters } = this.state;
    const { allStudents } = this.state;
    let students = [...allStudents];
    Object.entries(filters).map((entry) => {
      const [key, value] = entry;
      console.log("filterEverything", filterFunctions[key]);
      if (filterFunctions[key] && value !== -1) {
        students = students.filter(filterFunctions[key](value));
      }
    });
    let studentsprogress = [...(this.state.studentsprogress || [])];
    studentsprogress = studentsprogress.filter((sp) =>
      students.map((u) => u.user_id).includes(sp.user_id)
    );
    console.log("filterEverything", students, studentsprogress);
    this.setState({ students, studentsprogress_filtered: studentsprogress });
  };
  render() {
    const {
      students,
      filters,
      groups,
      studentsprogress,
      studentsprogress_filtered,
      statusList,
      selectCourseModel,
      selectedCourses = [],
      courses,
    } = this.state;
    console.debug("render/selectedCourses:", selectedCourses);
    const { report, course_ids, onShowMessage, _t, fetchTranslations } =
      this.props;
    const columns = [
      //
      { field: "__check__" },
      { field: "citizen_id", headerName: _t("ID"), flex: 1 },
      { field: "full_name", flex: 1, headerName: _t("Name") },
      { field: "email", headerName: _t("Email"), flex: 1 },
      {
        field: "progress",
        headerName: _t("Progress"),
        flex: 1,
        renderCell: (params) => <div>{params.value}</div>,
      },
    ];
    // disable report button if there isent student selected
    const canSeeReport =
      !Array.isArray(filters.selectedOnly.items) ||
      filters.selectedOnly.items.length === 0;
    return (
      <>
        {report === "students" && (
          <ReportStudents
            {...{
              filters,
              groups,
              studentsprogress,
              onShowMessage,
              report,
              _t,
              fetchTranslations,
            }}
            students={filters.selectedOnly.students}
          />
        )}
        {report === "programs" && (
          <ReportPrograms
            {...{
              filters,
              groups,
              studentsprogress,
              onShowMessage,
              report,
              _t,
              fetchTranslations,
            }}
            students={filters.selectedOnly.students}
          />
        )}
        {report === "extended" && (
          <ReportStudentsExtended
            {...{
              filters,
              groups,
              studentsprogress,
              onShowMessage,
              report,
              _t,
              fetchTranslations,
            }}
            students={filters.selectedOnly.students}
            // students={studentsprogress_filtered}
            // {...{ onShowMessage }}
          />
        )}
        {report === "course" && (
          <ReportCourses
            {...{
              filters,
              groups,
              studentsprogress,
              onShowMessage,
              report,
              course_ids,
              _t,
              fetchTranslations,
            }}
            students={filters.selectedOnly.students}
          />
        )}
        {report &&
          report !== "students" &&
          report !== "programs" &&
          report !== "extended" &&
          report !== "course" && <Page404 />}
        {!report && (
          <div className="bubble student-list">
            <div className="block">
              <FormGroup row className="filter-block">
                {Array.isArray(statusList) && (
                  <FormControlLabel
                    control={
                      <Select
                        fullWidth
                        className="program-select"
                        // native
                        value={filters["status"]}
                        onChange={(event) => this.onSelect2(event, "status")}
                      >
                        <MenuItem value={-1}>{_t("All")}</MenuItem>
                        {statusList.map((i) => (
                          <MenuItem value={i.status_title}>
                            {_t(i.status_title)}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    label={_t("Status")}
                    labelPlacement="top"
                  />
                )}
                <FormControlLabel
                  control={
                    <Select
                      fullWidth
                      className="program-select"
                      // native
                      value={filters["group"]}
                      onChange={(event) => this.onSelect2(event, "group")}
                      label="Groups:"
                    >
                      <MenuItem value={-1}>All</MenuItem>
                      {Array.isArray(groups) &&
                        groups.map((g) => (
                          <MenuItem value={g.student_group_id}>
                            {g.title}
                          </MenuItem>
                        ))}
                    </Select>
                  }
                  label={_t("Groups")}
                  labelPlacement="top"
                />

                <FormControlLabel
                  control={
                    <Switch
                      value={filters.selectedOnly.on}
                      onClick={() => {
                        let filters = { ...this.state.filters };
                        filters.selectedOnly.on = !filters.selectedOnly.on;
                        this.setState({ filters }, this.filterEverything);
                      }}
                    />
                  }
                  label={_t("Show selected only")}
                  labelPlacement="top"
                />

                <DebounceSearch
                  search={filters["search"]}
                  placeholder={_t("Search")}
                  onChange={(value) => {
                    let { filters } = this.state;
                    filters = { ...filters, search: value };
                    this.setState({ filters }, this.filterEverything);
                  }}
                />
              </FormGroup>
            </div>
            <div
              style={{
                width: "100%",
              }}
            >
              <LocalizedDataGrid
                loading={!Boolean(students)}
                rows={students || []}
                {...{ columns }}
                pageSize={7}
                rowHeight={70}
                autoHeight
                onSelectionModelChange={(model) => {
                  return this.onSelect(model);
                }}
                checkboxSelection
              />
              <div className="buttons">
                {[
                  { title: _t("Grades Report"), link: "/reports/students" },
                  { title: _t("Extended Report"), link: "/reports/extended" },
                  { title: _t("Program Report"), link: "/reports/programs" },
                  //{ title: _t("Course Report"), link: "/reports/course" },
                ].map((b) => (
                  <Button
                    variant="contained"
                    className={b.className || ""}
                    color="primary"
                    component={Link}
                    to={b.link}
                    disabled={canSeeReport}
                  >
                    {b.icon ? b.icon : <Description />}
                    {b.title}
                  </Button>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={canSeeReport}
                  onClick={() =>
                    this.setState(
                      { selectCourseModel: true, courses: null },
                      this.fetchCourses
                    )
                  }
                >
                  <Description />
                  {_t("Course Report")}
                </Button>
              </div>
            </div>
          </div>
        )}
        {selectCourseModel && courses && (
          <ModalWindow
            open={selectCourseModel}
            onClose={() => {
              this.setState({
                selectCourseModel: false,
                selectedCourses: null,
              });
            }}
            header={_t("Select courses for the report")}
          >
            <SelectListExtended
              className="course-select-list"
              items={courses.reduce(
                // reduce to { id1: title1, id2: title2 ... } object
                (res, c) => ({
                  ...res,
                  [c.course_id]: `${c.course_code} : ${c.title}`,
                }),
                {}
              )}
              // onChange receives the list of course ids (as strings)
              onChange={(selectedCourses) => this.setState({ selectedCourses })}
            />
            {/* <SelectList
              items={courses}
              className="course-select-list"
              onItemClick={(c) => {
                let selectedVal = [...(selectedCourses || [])];
                if (selectedVal.includes(c.course_id)) {
                  selectedVal = selectedVal.filter((i) => i !== c.course_id);
                } else {
                  selectedVal.push(c.course_id);
                }
                this.setState({ selectedCourses: selectedVal });
              }}
              ItemRenderer={({ item }) => (
                <span
                  className={
                    selectedCourses?.includes(item.course_id)
                      ? "selected-course"
                      : "not-selected-course"
                  }
                >
                  {selectedCourses?.includes(item.course_id) && <Check />}
                  {item.title}
                </span>
              )}
              onSearch={(item, value) =>
                item.title.toLowerCase().includes(value.toLowerCase()) ||
                item.course_code.toLowerCase().includes(value.toLowerCase())
              }
              searchProps={{
                placeholder: _t("Search"),
              }}
            /> */}
            <Typography>
              {(selectedCourses || []).length} items selected
            </Typography>
            <div>
              <Button
                variant="contained"
                color="primary"
                disabled={!(selectedCourses && selectedCourses.length > 0)}
                component={Link}
                onClick={() => {
                  console.log("test select course", this.state);
                  this.setState({
                    selectCourseModel: false,
                    selectedCourses: null,
                  });
                }}
                to={`/reports/course/${
                  (selectedCourses || []).length < (courses || []).length
                    ? (selectedCourses || []).join("|")
                    : ""
                }
                `}
              >
                OK
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  this.setState({
                    selectCourseModel: false,
                    selectedCourses: null,
                  })
                }
              >
                CANCEL
              </Button>
            </div>
          </ModalWindow>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};
export default connect(
  null,
  mapDispatchToProps
)(WithTranslations(ReportsStudentsList));
