import React from "react";
import UserService from "../../../services/UserService";
// import ProgramService from "../../../services/ProgramService";
import {
  LinearProgress,
  // ButtonGroup,
  // Button,
  Typography,
  // Dialog,
  // DialogActions,
  // DialogTitle,
  // Tooltip,
  // Zoom,
  // Container,
} from "@material-ui/core";
// import GroupAddIcon from "@material-ui/icons/GroupAdd";
// import PersonIcon from "@material-ui/icons/Person";
// import DeleteIcon from "@material-ui/icons/Delete";
// import AutoBreadcrumbs from "@bit/ses-education.ses-courses-shared-components.template.auto-breadcrumbs";
// import { Home } from "@material-ui/icons";
// import TeacherForm from "./teacher-form";
// import ConfirmDialog from "../../template/ConfirmDialog";
// import { Link } from "react-router-dom";
// import { statusDescription } from "../../../data/statusDescription";
import { 
  GridOverlay } from "@material-ui/data-grid";
import { connect } from "react-redux";
import { showMessage } from "../../../redux/notificationActions";
import Error from "../../template/Error";
import "./users.scss";
import Spinner from "../../template/Spinner";
import TeachersList from "./teachers-list";
import Teacher from "./teacher";
import OrganizationService from "../../../services/OrganizationService";
import AuthService from "../../../services/AuthService";

class Teachers extends React.Component {
  state = {
    id: null,
    users: [],
    user: null,
    programs: null,
    groups: null,
    user_groups: null,
    rows: [],
    loading: false,
    confirmDialog: false,
    delID: null,
    error: null,
    addModalOpen: false,
    renderAddModal: false, // triggers the rendering of ModalAddStudents
    user: null,
  };

  async componentDidMount() {
    this.fetchUsers();
  }

  componentDidUpdate( prevProps ){
    if( prevProps.match.params.user_id !== this.props.match.params.user_id){
      this.selectUser();
    }
  }
  onChange = () => {
    this.fetchUsers();
    this.selectUser();
  }
  selectUser(){
    let {user_id} = this.props.match.params || {};
    
    // cast to integer to compare with user ids
    user_id = user_id ? parseInt(user_id) : user_id;

    const {users, groups} = this.state;
    const user = Array.isArray(users) ? users.find( u => u.id === user_id ) : null;

    const user_groups = (Array.isArray(groups) ? 
    // choose only current teacher's groups
    groups.filter( g => Array.isArray(g.teachers) && g.teachers.findIndex( t => t.id === user_id ) > -1 ) : []) 
    // add id field to every item
    .map( g => ({...g, id: g.student_group_id}))  ;

    console.debug("selectUser user_id, users, user", user_id, users, user);
    this.setState({user, user_groups})
  }

  fetchUsers = async ()  => {
    try {
      let groups, users, error ;
      
      users = await UserService.getUsers();
      groups = await OrganizationService.getStudentGroups();

      users = Array.isArray(users) ? users.filter(
        (user) => user.credentials && user.credentials.includes("teacher")
      ) : false ;

      if( !users ) error = UserService.error || "Failed loading users";
      if( !groups ) error = OrganizationService.error || "Failed loading student groups";
      
      
      this.setState({ users, groups, error }, () => this.selectUser() );      
      return true;

      // if (users) {
      //   // only populate state with STUDENT users
      //   teacherUsers = users.filter(
      //     (user) => user.credentials && user.credentials.includes("teacher")
      //   );
      //   // this.setState({ users: teacherUsers }, () => this.selectUser() );
      //   // this.populateRows(teacherUsers);
      //   return true;
      // } else {
      //   this.setState({ error: "Failed loading users", user: null });
      //   return false;
      // }
    } catch (err) {
      // this.props.onShowMessage( `Fetching users from server failed.`, "error");
      this.props.onShowMessage( UserService.error || `Fetching users from server failed.`, "error");
      return false;
    }
  }

 

  loadingOverlay() {
    return (
      <GridOverlay>
        <div style={{ position: "absolute", top: 0, width: "100%" }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  }

  populateProgressDetails(course_progress) {
    return (
      <Typography>
        {course_progress.map((item) => {
          return (
            <ul className="tooltip-list">
              <li>
                <span>{item.course_code}</span>
                <span>&nbsp;</span>
                <span>{item.progress + "%"}</span>
              </li>
            </ul>
          );
        })}
      </Typography>
    );
  }

  closeDialogHandler() {
    this.setState({ confirmDialog: false, delID: null });
  }

  async deleteUserHandler() {
    const { delID } = this.state;
    const result = await UserService.deleteOrganizationUser(delID);
    if (result) {
      this.props.onShowMessage(`User with id ${delID} was deleted.`, "info");
      this.fetchUsers();
    } else {
      this.props.onShowMessage(
        `Error deleting user, please remove assigned programs before deletion.`,
        "error"
      );
    }
    this.closeDialogHandler();
  }

  closeAddModal() {
    this.setState({
      addModalOpen: false,
      renderAddModal: false,
    });
  }

  onFormSubmit = (data) => {
    console.debug("submitted:", data);
  };
  onGroupUpdate = async ( data ) => {
    const { user_id } = this.props.match ? this.props.match.params : {};
    const {onShowMessage} = this.props;

    console.debug("updating group", data);
    // return true;
    const result = await OrganizationService.updateStudentGroup(data);
    if( ! result ){
        onShowMessage( OrganizationService.error || "Unknown error!", "error" );
        return false;
    }
    else{
      this.onAssignTeachers([user_id], result);
    }
    // reload group list
    this.fetchUsers();

    onShowMessage( "Group updated" );
    return true;
  };
  onAssignTeachers = async ( teachers,group_id ) => {
    const {onShowMessage} = this.props;
    if( !await OrganizationService["assignTeachers"](group_id, teachers) ){
      onShowMessage( OrganizationService.error || `Unknown error assigning teachers`, "error" )
      return false;
    }
  };
  onUnAssignTeachers = async ( teachers,group_id ) => {
    const {onShowMessage} = this.props;
    if( !await OrganizationService["unassignTeachers"](group_id, teachers) ){
      onShowMessage( OrganizationService.error || `Unknown error unassigning teachers`, "error" )
      return false;
    }
    else{
      // reload group list
      this.fetchUsers();
    }
  };
  changeTeacherPassword = async (password) => {
    const { user:currentUser } = this.state;
    if (!currentUser)
      return this.props.onShowMessage("No user selected", "error");

     if (!(await AuthService.changeTeacherPassword(currentUser.id, password))) {
       return this.props.onShowMessage(AuthService.error, "error");
     }

    this.props.onShowMessage( "Password successfully changed", "success");
    return true;
  };
  render() {
    const { user_id } = this.props.match ? this.props.match.params : {};
    const {
      users,
      user = {},
      user_groups,
      error,      
    } = this.state;
    
    console.debug("teachers user", user)
    const {onShowMessage} = this.props;

    if (users === null) return <Spinner />;

    if (error) return <Error {...{ error }} />;

    return user_id ? (
      <Teacher {...{
        ...user || {}, 
        ...this.props,user,
        ...this.props.match, 
        groups: user_groups || [], 
        onGroupUpdate:this.onGroupUpdate, 
        onunAssignTeachers:this.onUnAssignTeachers,
        onChange: this.onChange, 
        onPasswordChange: this.changeTeacherPassword
        } } 
      />
    ) : (
      <TeachersList {...{ ...this.state, onShowMessage }} loadingOverlay={this.loadingOverlay} fetchUsers={this.fetchUsers} />
    );
    
  }
}
const stateToProps = (state) => ({ user: state.auth.user });

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default connect(stateToProps, mapDispatchToProps)(Teachers);
