import { Button, TextField, Typography } from "@material-ui/core";
import React from "react";
import Spinner from "../../template/Spinner";
import ModalWindow from "@bit/ses-education.ses-courses-shared-components.modal-window";
import WithTranslations from "../../WithTranslations";

const defaultState = {
  user: null,
  original: null,
  error: null,
}

class TeacherForm extends React.Component {
  state = {
    ...defaultState
  };

  componentDidMount() {
    this.props.fetchTranslations([
      "Create new teacher",
      "Cancel",
      "Save",
    ]);
    this.dataToState();
  }
  componentDidUpdate( prevProps) {
      if( prevProps.user !== this.props.user)
        this.dataToState();
  }

  dataToState() {
    const { user = {} } = this.props;
    this.setState({ user, original: { ...user } });
  }

  setValue = ({ target }) => {
    const { name, value, check } = target;

    let { user } = this.state;

    user = { ...user, [name]: value };
    this.setState({ user });
  };

  validate(ev){
    const autovalidated = ev.currentTarget.reportValidity();

    // TODO: check values of each field
    return autovalidated;
  }

  onFormSubmit = async (ev) => {
    // stop sending the form
    ev.preventDefault();

    const {onSubmit, onClose} = this.props;

    // validate form and call callback
    if( this.validate(ev) && typeof onSubmit === "function" && await onSubmit( this.state.user )){
        // calback returned true - clear and close the form
        this.setState({
          ...defaultState
        })

        if( typeof onClose === "function" ) onClose();
    }
  }

  render() {
    const { onSubmit, onClose, _t, ...other } = this.props;
    const { user } = this.state;
    if (user === null) return <Spinner />;

    const { id, name, last_name, email } = user;

    const buttons = <>
    <Button variant="contained" color="primary" type="submit">
              {_t("Save")}
            </Button>
            <Button variant="contained" color="secondary" onClick={onClose}>
              {_t("Cancel")}
            </Button>
    </>

    return (
      <ModalWindow 
      formClassName="teacher-form" header={id ? `Edit teacher #${id}` : _t("Create new teacher")} 
      onSubmit={this.onFormSubmit}
      {...{...other, buttons, onClose}} 
      >                  
          {id && <div className="id-value">#{id}</div>}
          <TextField
            label="Name"
            name="name"
            value={name}
            onChange={this.setValue}
            fullWidth
            required
          />
          <TextField
            label="Last name"
            name="last_name"
            value={last_name}
            onChange={this.setValue}
            fullWidth
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            required
            value={email}
            onChange={this.setValue}
            fullWidth
          />                  
      </ModalWindow>
    );
  }
}

export default WithTranslations(TeacherForm);
