import React from "react";
import { List, ListItem, TextField } from "@material-ui/core";
import "./select-list.scss";
import DebounceSearch from "../DebounceSearch";

/**
 * Renders a List of items and allows to click on them
 * @prop {function} onSeach( item, searchString) - callback function to be called when search is changed. Receives object from list to search within and the search string;
 */
class SelectList extends React.Component {
  state = {
    search: "",
    filteredItems: [],
  };

  componentDidMount() {
    this.setState({
      filteredItems: this.filterItems(this.props.items, this.state.search),
    });
  }
  componentDidUpdate(prevProps) {
    const { items } = this.props;
    if (JSON.stringify(items) !== JSON.stringify(prevProps.items)) {
      this.setState({
        filteredItems: this.filterItems(items, this.state.search),
      });
    }
  }

  filterItems = (items, search) => {
    const { onSearch } = this.props;
    if (search === "" || !search || typeof onSearch !== "function")
      return items;
    console.debug("filterItems", items, search);
    return items.filter((item) => onSearch(item, search));
  };

  onSearch = (search) => {
    console.debug("setting search", search);
    const filteredItems = this.filterItems(this.props.items, search);
    this.setState({ search, filteredItems });
  };

  render() {
    let {
      items,
      className,
      onItemClick = console.debug,
      onSearch,
      searchProps = {},
      listId,
      noItemsMessage = "no items",
      ItemRenderer = (item) => <span>{(item && item.title) || "-"}</span>,
      beforeSearch = <></>,
      afterSearch = <></>,
      ...other
    } = this.props;
    className = className ? `${className} select-list` : "select-list";

    console.debug("selectList items:", items);

    const { search, filteredItems } = this.state;

    return (
      <div className={className}>
        <div className="actions">
          {beforeSearch}
          <DebounceSearch
            fullWidth
            search={search}
            onChange={this.onSearch}
            {...searchProps}
            //   variant="outlined"
          />
          {afterSearch}
        </div>
        <List {...other}>
          {
            // if no items are provided, show a message
            (!Array.isArray(items) || items.length === 0) && noItemsMessage
          }
          {Array.isArray(items) &&
            filteredItems.map((item, index) => (
              <ListItem
                key={`${listId}-item-${index}`}
                onClick={() => onItemClick(item)}
                className="select-list-item"
              >
                <ItemRenderer {...{ item, index }} />
              </ListItem>
            ))}
        </List>
      </div>
    );
  }
}

export default SelectList;
