import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import dataGridLocalize from "../../../data/datagrid-localize";
import WithTranslations from "../../WithTranslations";

/**
 * The LocalizedAdminTable component is a wrapper for the DataGrid component.
 * It is used to localize the DataGrid component with the correct language.
 * @usage <LocalizedAdminTable language_id={"zh"}
 *  // pass any other props here
 *  />
 * @param {Object} props - All props are passed to nested AdminTable component except for the language_id prop that is used to localize the AdminTable component.
 * @returns {ReactElement} - The rendered component.
 */
class LocalizedAdminTable extends React.Component {
  render() {
    const { _language, ...otherProps } = this.props;
    const localization = dataGridLocalize(_language || "en");
    return <DataGrid localeText={localization} {...otherProps} />;
  }
}

export default WithTranslations(LocalizedAdminTable);
