import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import React from "react";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./user-progress.scss";
import { strFormat } from "../../../utils/functions";
import { Link } from "react-router-dom";
import WithTranslations from "../../WithTranslations";

class UserProgress extends React.Component {
  componentDidMount() {
    this.props.fetchTranslations([
      "Programs"
    ]);
  }
  calculateProgramProgress(courses) {
    // const { currentUser } = this.state;
    const { currentUser } = this.props;

    console.debug("Courses:", courses);
    if (!Array.isArray(courses) || !courses[0]) return 0;

    const result = courses.map((c) => {
      const course =
        currentUser.course_progress &&
        currentUser.course_progress.find(
          (item) => item.course_id === c.course_id
        );
      if (!course || course.progress === null) return 0;
      return course.progress;
    });
    let sum = result.reduce(function (sum, value) {
      return sum + value;
    }, 0);

    return Math.round(sum / result.length);
  }

  render() {
    const {
      currentUser,
      programs,
      defaultExpanded = false,
      courseLink = null,
      _t,
    } = this.props;
    console.log("currentUser:", currentUser);
    return (
      <>
        <Typography variant="h2">{_t("Programs")}</Typography>
        <div className="progress-container">
          {currentUser && Array.isArray(currentUser.programs) &&
          currentUser.programs.map((program) => {
            // find the right program in state programs
            const courses = programs.find(
              (p) => p.program_id === program.program_id
            ).courses;

            // calculate program progress %
            const progress = this.calculateProgramProgress(courses);

            return (
              <Accordion className="accorion-item" {...{ defaultExpanded }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id="accordion-title"
                >
                  <Typography
                    id="accordion-title-text"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    {program.title}
                  </Typography>
                  <Typography
                    id="accordion-title-text"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    {progress + "%"}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="accorion-details">
                  {!courses || courses[0] === null ? (
                    <Typography>No courses</Typography>
                  ) : (
                    courses.map((c) => {
                      const course =
                        currentUser.course_progress &&
                        currentUser.course_progress.find(
                          (item) => item.course_id === c.course_id
                        );

                      return (
                        <div className="line">
                          <Typography>
                            {courseLink ? (
                              <Link
                                to={strFormat(courseLink, {
                                  "%course_id%": c.course_id,
                                })}
                              >
                                {`${c.course_code} : ${c.title}`}
                              </Link>
                            ) : (
                              `${c.course_code} : ${c.title}`
                            )}
                          </Typography>

                          {course ? (
                            course.progress === 100 ? (
                              <Typography className="score-container">
                                {course.total_score}
                                <EmojiEventsIcon className="grade-icon" />
                              </Typography>
                            ) : (
                              <div
                                className="score-container"
                                dataProgress={parseInt(course.progress)}
                              >
                                <LinearProgress
                                  className="progress-bar"
                                  variant="determinate"
                                  value={course.progress}
                                />
                                {parseInt(course.progress)}%
                              </div>
                            )
                          ) : (
                            <div className="score-container">
                              <LinearProgress
                                className="progress-bar"
                                variant="determinate"
                                value={0}
                              />
                              0%
                            </div>
                          )}
                        </div>
                      );
                    })
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </>
    );
  }
}

export default WithTranslations(UserProgress);
