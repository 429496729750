import React from 'react'
import { connect } from 'react-redux'
import TranslationService from '../../services/TranslationService'

/**
 * HOC to encapsulate translations fetching from service and inject them into props.
 * @usage Wrap component with WithTranslations() before exporting:
 * class Something extends React.Component {... }
 * export default WithTranslations( Something )
 * 
 * Then use props.fetchTranslations( ["Yes", "No"]) to fetch the needed translations 
 * ```js
 * and props.translations object to receive them in render() :
 * render(){
 *      const {translations, noTranslationsLoaded} = this.props
 *      // use noTranslationsLoaded() to check whether the translations were loaded
 *      if( noTranslationsLoaded() ) return <div>Loading...</div>
 *      // translations loaded, use them in render
 *      return <>
    *             <Button>{translations['Yes']}</Button> {// fetch directly from translations object }
    *             <Button>{_t('No')}</Button>  {// use the handy _t() function }
 *             </>
 * } ```
 * 
 */
const WithTranslations = Wrapped => {


    const stateToProps = (state) => ({
        _language: state.auth.language
    })

    return connect(stateToProps)(class extends React.Component{
    
        state = {
            translations: {},
            translationError: null,
            translationsLoaded: false,
            translation_ids: [],
        }

        componentDidUpdate(prevProps){
            console.log("with translation did update", prevProps._language, " ", this.props._language)
            if( prevProps._language !== this.props._language ){
                console.debug("WithTranslation component updated and language changed to", this.props._language)
                this.setState({ translationsLoaded: false })
                this.obtainTranslationsFromService.bind(this)();
            }
        }

        async obtainTranslationsFromService() {
            console.debug("WithTranslation obtainTranslationsFromService")
            const result = await TranslationService.get( this.state.translation_ids );
            console.debug("WithTranslation obtainTranslationsFromService called TranslationService.get with", this.state.translation_ids)

            if( !result ) {
                // error happened
                this.setState({translationError: TranslationService.error } )
                return;
            }

            const {translations} = this.state;

            // insert/overwrite result to existing translations
            // reset error to null
            this.setState( { translations: {...translations, ...result }, translationError: null, translationsLoaded: true } );
        }

        fetchTranslations = async ( ids ) => {
            console.debug("WithTranslations fetchTranslations called with", ids)
            this.setState({ translation_ids: ids }, this.obtainTranslationsFromService);
        }

        noTranslationsLoaded = () => Object.keys(this.state.translations).length === 0;

        /**
         * Fetch translation from translations object or return key as is, if not found
         * @param {*} index 
         * @returns 
         */
        translate = index => this.state.translations[index] || index;
    
        render(){
            const {translations, translationError, translationsLoaded } = this.state;
            // inject translations object, translation error, fetchTranslations and noTranslationsLoaded functions into child props
            return <Wrapped {...{
                ...this.props, 
                translations,
                _t: this.translate,
                translationError, 
                fetchTranslations: this.fetchTranslations,
                // noTranslationsLoaded: this.noTranslationsLoaded,
                translationsLoaded
            } } />
        }
    })
}


export default WithTranslations