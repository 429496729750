import React from 'react'
import OrganizationService from '../../../services/OrganizationService';
import {Container} from "@material-ui/core";
import Spinner from  "../../template/Spinner";
import Error from '../../template/Error';
import Page404 from '../../template/Page404'
import "./style.scss";
import Group from './group';
import GroupList from './group-list';
import { connect } from 'react-redux';
import { showMessage } from '../../../redux/notificationActions';
import GroupStudent from './group-student';
import UserService from '../../../services/UserService';
import { Home } from '@material-ui/icons';
// import AutoBreadcrumbs from "@bit/ses-education.ses-courses-shared-components.template.auto-breadcrumbs";
import AutoBreadcrumbs from "../../template/CustomBreadcrumbs";
import ProgramService from '../../../services/ProgramService';
import AuthService from '../../../services/AuthService';
import WithTranslations from '../../WithTranslations';
// import GroupStudentCourse from './group-student-course';

class StudentGroups extends React.Component{

    state = {
        groups: null,
        group: null,
        error: null,
        current_user: null,
        breadcrumbs : null,
    }

    componentDidMount(){
        this.props.fetchTranslations([
            "Student groups",
            "Back",
        ]);       
        this.fetchData();
    }

    componentDidUpdate( prevProps ){
        if(prevProps._language != this.props._language || (!prevProps.translationsLoaded && this.props.translationsLoaded)){ 
            this.fetchData();   
        }
        if( 
            prevProps.match.params.group_id !== this.props.match.params.group_id ||
            prevProps.match.params.user_id !== this.props.match.params.user_id ||
            prevProps.match.params.course_id !== this.props.match.params.course_id
            ){
            this.selectData();
        }
      }

    selectData(){
        const {_t} = this.props;
        const current_user = this.selectUser();
        const group = this.selectGroup();
        const course = this.selectCourse();

        let breadcrumbs = [
            { icon: <Home />, to: "/" },
            { text: _t("Student groups"), to: "/groups" }            
        ]

        if( group && group.title ) breadcrumbs = [...breadcrumbs,
            current_user ? {text: group.title, to: `/groups/${group.student_group_id}`} : {text: group.title }
        ]

        if( current_user && current_user.name ) breadcrumbs = [...breadcrumbs,
            {text: `${current_user.name}${current_user.last_name ? ` ${current_user.last_name}` : "" }`, 
            to:  `/groups/${group.student_group_id}/${current_user.id}` }
        ]

        if( course )breadcrumbs = [...breadcrumbs,
            {text: course.course_code }
        ]

        console.debug( "selectData course:", course)

        this.setState({current_user, group, course, breadcrumbs})
    }  

    selectCourse(){
        let {course_id} = this.props.match.params || {};

        if( !course_id ) return null;

        course_id = parseInt(course_id); // to compare to course id in data

        const {programs} = this.state;
        // console.debug("selectCourse programs: ", programs);

        if( !programs ) return null;

        const program = programs.find( p => p.courses && p.courses.find(c => c.course_id === course_id) )
        // console.debug("selectCourse program: ", program);
        if( !program ) return null;

        return program.courses.find(c => c.course_id === course_id);
    }

    selectGroup(){
        let {group_id} = this.props.match.params || {};

        console.debug("selectGroup group id:", group_id)
        
        // if not a number - don't do anything
        if( isNaN(group_id) ){
            console.debug("selectGroup group id is not a number")
            // this.setState({ group: null });            
            return null;
        }

        // cast to int to compare with group ids
        group_id = group_id ? parseInt(group_id) : group_id;

        const {groups} = this.state;
        console.debug("selectGroup groups:", groups)
        const group = group_id && Array.isArray(groups) ? groups.find( g => g.student_group_id === group_id ) : false;
        console.debug("selectGroup group:", group)
        // this.setState({group});
        return group;
    }
    
    selectUser(){
        console.debug("selectUser params", this.props.match.params)
        let {user_id} = this.props.match.params || {};

        console.debug("selectUser user id:", user_id)
        
        // if not a number - don't do anything
        if( isNaN(user_id) ){
            console.debug("selectUser user id is not a number")
            // this.setState({ current_user: null });
            return false;
        }

        // cast to int to compare with group ids
        user_id = user_id ? parseInt(user_id) : user_id;

        const {users} = this.state;
        
        let current_user = user_id && Array.isArray(users) ? users.find( u => u.id === user_id ) : false;        

        console.debug("selectUser user:", current_user)
        // this.setState({current_user});
        return current_user;
    }

    fetchData = async() =>{
        let groups = await OrganizationService.getStudentGroups();
        
        let users = await UserService.getUsers();
        
        const error = groups ? null : OrganizationService.error || "Unknown error";
        
        groups = Array.isArray(groups) ?
            groups.map( g => ({
                ...g, 
                    // add id field required by LocalizedDataGrid
                    id: g.student_group_id                
                }) ) : groups;

        const programs = await ProgramService.getPrograms();
        console.log({groups, users, programs, error })
        this.setState({groups, users, programs, error }, 
            // when state is set, select data based on url
            () => {
                this.selectData();                
        } )
    }
    
    onGroupUpdate = async ( data ) => {
        const {onShowMessage} = this.props;

        console.debug("updating group", data);
        // return true;
        if( ! await OrganizationService.updateStudentGroup(data) ){
            onShowMessage( OrganizationService.error || "Unknown error!", "error" );
            return false;
        }
        
        // reload group list
        this.fetchData();

        onShowMessage( "Group updated" );
        return true;
    }

    onDeleteRecord = async (deleteRecord ) =>{        
        const {onShowMessage} = this.props;    
        if( ! deleteRecord || !deleteRecord.student_group_id ){
          console.error("Wrong group data set for deletion: ", deleteRecord)
          onShowMessage("Wrong group data", "error")
          // close confirmation dialog
          this.setState({deleteRecord: null})
          return false;
        }

        if( ! await OrganizationService.deleteStudentGroup(deleteRecord.student_group_id) ){
            onShowMessage( OrganizationService.error || "Unknown error deleting group", "error" )
            return false;
        }

        onShowMessage("Group successfully deleted");
        this.fetchData();
    }

    onArchiveRecord = async ( archiveRecord ) =>{
        const {onShowMessage} = this.props;    
        if( ! archiveRecord || !archiveRecord.student_group_id ){
          console.error("Wrong group data set for archiving: ", archiveRecord)
          onShowMessage("Wrong group data", "error")          
          return false;
        }

        if( ! await OrganizationService.archiveStudentGroup(archiveRecord.student_group_id) ){
            onShowMessage( OrganizationService.error || "Unknown error archiving group", "error" )
            return false;
        }

        onShowMessage("Group successfully archived");
        this.fetchData();
    
    }

    onUnarchiveRecord = async ( unarchiveRecord ) =>{
        const {onShowMessage} = this.props;    
        if( ! unarchiveRecord || !unarchiveRecord.student_group_id ){
          console.error("Wrong group data set for activating: ", unarchiveRecord)
          onShowMessage("Wrong group data", "error")          
          return false;
        }

        if( ! await OrganizationService.unarchiveStudentGroup(unarchiveRecord.student_group_id) ){
            onShowMessage( OrganizationService.error || "Unknown error activating group", "error" )
            return false;
        }

        onShowMessage("Group successfully activated");
        this.fetchData();
    
    }

    assignUsers  = async( users, type ) => {
        const {group} = this.state;
        const {onShowMessage} = this.props;    

        
        // if no group is stored in state - show error
        if( !group || !group.student_group_id ) {
            onShowMessage( "Wrong group data passed", "error" )
            return false;
        }
        
        let assignFunction, assignText;
        switch(type){
            case "assign-students":
                assignFunction = "assignStudents";
                assignText = "assigning students"
                break;
            case "assign-teachers":
                assignFunction = "assignTeachers";
                assignText = "assigning teachers"
                break;
            case "unassign-students":
                assignFunction = "unassignStudents";
                assignText = "unassigning students"
                break;
            case "unassign-teachers":
                assignFunction = "unassignTeachers";
                assignText = "unassigning teachers"
                break;
            
            default:
                onShowMessage( "Wrong function called", "error" )
                return false;
                break;
        }

        if( typeof OrganizationService[assignFunction] !== "function" ) {
            onShowMessage( "No function called", "error" )
            return false;
        }
        

        if( !await OrganizationService[assignFunction]( group.student_group_id, users) ){
            onShowMessage( OrganizationService.error || `Unknown error ${assignText}`, "error" )
            return false;
        }

        onShowMessage('Operation successfully completed');
        this.fetchData();
        return true;
    }

    onAssignStudents = async ( students ) => {
        const result = await this.assignUsers( students, "assign-students");
        if(result){
            this.fetchData();
        }
        return result;
    }

    onUnassignStudents = async ( students ) => {
        return this.assignUsers(students, "unassign-students");
    }
    
    onAssignTeachers = async ( teachers ) => {
        const result = await this.assignUsers( teachers, "assign-teachers");
        if(result){
            this.fetchData();
        }
        return result;
    }

    onUnassignTeachers = async ( teachers ) => {
        return this.assignUsers(teachers, "unassign-teachers");
    }
    changeStudentsPassword = async (password) => {
        const { current_user:currentUser } = this.state;
    if (!currentUser)
      return this.props.onShowMessage("No user selected", "error");

    if (!(await AuthService.changeStudentPassword(currentUser.id, password))) {
      return this.props.onShowMessage(AuthService.error, "error");
    }

    this.props.onShowMessage( "Password successfully changed", "success");
    return true;
    }

    
    render(){
        const {groups, users, error, group, programs, current_user, course, breadcrumbs} = this.state;
        const {group_id, user_id, course_id} = this.props.match ? this.props.match.params : {};        
        const {user, onShowMessage, _t} = this.props;
        // console.debug("Group:", group)        

        const isOrganization =
            Array.isArray(user.credentials) &&
            user.credentials.includes("organization");

        if( groups === null || programs === null ) return <Spinner />

        if( error ) return <Error {...{error}} />

        return(
            <Container className="student-groups">     
                <AutoBreadcrumbs
                items={breadcrumbs}
                backLink={ group_id ? user_id ? course_id ? `/groups/${group_id}/${user_id}`: `/groups/${group_id}` : `/groups` : null }
                backText={_t("Back")}
                />   
                
                { /* Group list  */ } 
                { !group_id && <GroupList 
                user={user}
                rows={groups} 
                onGroupUpdate={this.onGroupUpdate}  
                onDeleteRecord={this.onDeleteRecord}
                onArchiveRecord={this.onArchiveRecord}
                onUnarchiveRecord={this.onUnarchiveRecord}
                {...{
                    isOrganization,
                    onShowMessage
                }}
                />}

                { /* Single group  */ }
                { group_id && group === null && <Spinner />}
                { group_id && group === false && <Page404 error="Unknown group" />}
                {group_id && group && !user_id &&
                // Group page
                <Group {... group || {}} 
                onUpdate={this.onGroupUpdate} 
                onAssignStudents={this.onAssignStudents} 
                onUnassignStudents={this.onUnassignStudents} 
                onAssignTeachers={this.onAssignTeachers} 
                onUnassignTeachers={this.onUnassignTeachers} 
                user={user}
                {...{
                    isOrganization,
                    onShowMessage
                }}
                /> }      

                { /* Single user  */ }
                {user_id && current_user === null && <Spinner /> } 
                {user_id && current_user === false && <Page404 error="Unknown user" /> } 
                { user_id && current_user &&
                // User page + user course progress
                <GroupStudent {...{...current_user || {}, current_user, programs, course_id, course, courseLink: `/groups/${group_id}/${user_id}/%course_id%`, onChange: this.fetchData, onPasswordChange: this.changeStudentsPassword }} />
                }
                
                { /* Single user's course progress  */ }
                {/* {course_id && course === null && <Spinner /> } 
                {course_id && course === false && <Page404 error="Unknown course" /> } 
                { course_id && course && <GroupStudent {...{...current_user || {}, programs}} />}
             */}
            </Container>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      onShowMessage: (message, type) => dispatch( showMessage(message, type)),
    };
  };
const stateToProps = (state) => ({
    user: state.auth.user
})

export default connect( stateToProps, mapDispatchToProps )(WithTranslations(StudentGroups))