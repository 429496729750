import {
  Tabs,
  Tab,
  List,
  Grid,
  TextField,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import React from "react";
import ProgramService from "../../../services/ProgramService";
import ConfirmDialog from "../../template/ConfirmDialog";
import Error from "../../template/Error";
import ImageSelector from "../../template/ImageSelector";
import Spinner from "../../template/Spinner";
import WithTranslations from "../../WithTranslations";
import SelectList from "../../template/SelectList";
import "./style.scss";

const defaultProgram = {
  program_id: null,
  title: "",
  description: "",
  images: [],
  courses: [],
};

const defaultImage =
  "https://images.ses-education.com/courses/course-no-image.png";

class EditProgram extends React.Component {
  state = {
    program: {},
    original: {},
    tab: 0,
    onConfirmDialog: false,
    allCourses: null,
    coursesNotInProgram: null,
  };

  componentDidMount() {
    this.props.fetchTranslations([
      "Add program",
      "Edit program",
      "Title",
      "Description",
      "programEditPrompt",
      "Available courses",
      "Courses in program",
      "Save courses list",
      "Reset to previous",
      "Search...",
      "Close",
      "Program image",
      "There are unsaved changes in program, do you really want to close and loose the changes?",
      "Properties",
      "Courses",
      "Cancel",
      "Yes",
      "Add",
    ]);

    this.initProgram();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.program !== this.props.program) {
      this.initProgram();
    }
  }

  initProgram() {
    let { program } = this.props;
    // failsafe for when program is not received or received as not an object
    program = program && typeof program === "object" ? program : {};

    this.setState(
      {
        program: {
          ...defaultProgram,
          ...program,
        },

        // set original program to compare later
        original: {
          ...defaultProgram,
          ...program,
        },
      },
      () => {
        this.fetchAllCourses();
      }
    );
  }

  fetchAllCourses = async () => {
    const { program } = this.state;
    const allCourses = await ProgramService.getAllCourses();
    console.debug("allCourses", allCourses);
    const coursesNotInProgram = this.filterCourses(allCourses, program);
    this.setState({
      allCourses,
      coursesNotInProgram,
      // if allCourses is falsy, set error to service's error value
      error: allCourses ? null : ProgramService.error || "Unknown error",
    });
  };

  filterCourses = (allCourses, program) => {
    if (!Array.isArray(allCourses)) return [];
    let { courses } = program;
    // failsafe for when courses is not received or received as not an array
    courses = Array.isArray(courses) ? courses : [];

    // get course ids list to compare with allCourses
    const course_ids = courses.map((c) => c.course_id);

    // find all courses that are not in program
    return (
      allCourses
        .filter((c) => course_ids.indexOf(c.course_id) === -1)
        // sort by course code
        .sort((a, b) => a.course_code?.localeCompare(b.course_code))
    );
  };

  onSave = () => {
    const { onSave = console.debug } = this.props;
    if (typeof onSave === "function") {
      onSave(this.state.program);
      return;
    }
    console.debug("onSave is not a function");
  };

  onSaveCourses = () => {
    const { onSaveCourses = console.debug } = this.props;
    if (typeof onSaveCourses === "function") {
      const { program } = this.state;
      // send program id and course id list
      onSaveCourses(
        program.program_id,
        program.courses.map((c) => c.course_id)
      );
      return;
    }
    console.debug("onSaveCourses is not a function");
  };

  onImageSelect = async (ev) => {
    //console.debug(image);

    // use onSaveImage callback to save image to database
    const { onSaveImage = console.debug } = this.props;
    if (typeof onSaveImage === "function") {
      // send program id and course id list
      await onSaveImage(ev);
      return;
    }
    console.debug("onSaveImage is not a function");
  };

  onImageDelete = async () => {
    // use onSaveImage callback to save image to database
    const { onDeleteImage = console.debug } = this.props;
    if (typeof onDeleteImage === "function") {
      // send program id and course id list
      await onDeleteImage();
      return;
    }
    console.debug("onDeleteImage is not a function");
  };
  /**
   * Move course from available to program courses or vice versa
   * @param {object} course - the course object
   * @param {string} operation either "add" or "remove". If "add" - course is added to program courses
   */
  onCourseClick = (course, operation) => {
    let { program, coursesNotInProgram } = this.state;
    let { courses = [] } = program;

    // fail safe for when courses is not received or received as not an array
    courses = Array.isArray(courses) ? courses : [];

    // const newCourses = [...courses];
    if (operation === "add") {
      // add course to program courses
      courses.push(course);
      // remove course from available courses
      coursesNotInProgram = coursesNotInProgram.filter(
        (c) => c.course_id !== course.course_id
      );
    } else if (operation === "remove") {
      // remove course from program courses
      courses = courses.filter((c) => c.course_id !== course.course_id);
      // add course to available courses
      coursesNotInProgram.push(course);
    }

    // sort both lists by course code
    courses.sort((a, b) => a.course_code.localeCompare(b.course_code));
    coursesNotInProgram.sort((a, b) =>
      a.course_code.localeCompare(b.course_code)
    );

    // update program with new courses
    program = { ...program, courses };
    // prepare updated list of courses not in program
    // const coursesNotInProgram = this.filterCourses(allCourses, program);
    // update program and the list
    this.setState({
      program,
      coursesNotInProgram,
    });
  };

  resetCoursesToOriginal = () => {
    const { original } = this.state;
    const { program } = this.state;

    // fail safe for when original courses is empty
    const originalCourses = Array.isArray(original.courses)
      ? original.courses
      : [];

    this.setState({
      program: { ...program, courses: [...originalCourses] },
    });
  };

  onFieldChange = ({ target }) => {
    const { name, value } = target;
    // update single field within state.program
    this.setState((prevState) => ({
      program: {
        ...prevState.program,
        [name]: value,
      },
    }));
  };

  /**
   * Search comparison for both course lists
   * @param {*} item
   * @param {*} searchString
   * @returns
   */
  onSearch = (item, searchString) => {
    searchString = searchString.toLowerCase();

    return (
      (item.course_code &&
        item.course_code.toLowerCase().includes(searchString)) ||
      (item.title && item.title.toLowerCase().includes(searchString))
    );
  };

  render() {
    const { _t, onClose } = this.props;
    const {
      program,
      original,
      tab,
      coursesNotInProgram,
      error,
      onConfirmDialog,
    } = this.state;
    const { program_id, title, description, images = [] } = program;

    // fetch separate parts of program and orinal program to compare
    let { courses, ...rest } = program;
    // failsafe for when courses are not received or received as not an array
    // the courses inside allCourses have also experiment and image data when the one in program dont have
    // so for the comperisone of coursesDirty you need to remove that data from courses
    courses = Array.isArray(courses)
      ? courses.map((course) => {
          const { course_code, course_id, title } = course;
          return { course_code, course_id, title };
        })
      : [];

    // the courses inside program have description whene the courses in allCourses dont have
    // so for the comperisone of coursesDirty you need to remove that data from originalCourses
    let { courses: originalCourses, ...restOriginal } = original;
    originalCourses = Array.isArray(originalCourses)
      ? originalCourses.map((course) => {
          const { course_code, course_id, title } = course;
          return { course_code, course_id, title };
        })
      : [];

    const programDirty = JSON.stringify(rest) !== JSON.stringify(restOriginal);
    const coursesDirty =
      JSON.stringify(courses) !== JSON.stringify(originalCourses);
    // while not received all courses, show spinner
    if (coursesNotInProgram === null) return <Spinner />;

    // if error received, show the error
    if (error) return <Error error={error} />;

    // helper component to render list item
    const ListItemRenderer = ({ item }) => (
      <>
        <span>{item.course_code}</span>
        <span>{item.title}</span>
      </>
    );

    console.debug("coursesNotInProgram", coursesNotInProgram);

    return (
      <div className="edit-program">
        {/* <h1>{program_id ? _t("Edit program") : _t("Add program")}</h1> */}
        {program_id && (
          <Tabs
            value={tab}
            onChange={(e, v) => this.setState({ tab: v })}
            indicatorColor="secondary"
            textColor="primary"
            className="tabs-container"
          >
            <Tab label={_t("Properties")} value={0} />
            <Tab label={_t("Courses")} value={1} />
          </Tabs>
        )}
        {tab === 0 && (
          <div className="form properties">
            <div className="fields-and-image">
              <div className="fields">
                <TextField
                  label={_t("Title")}
                  value={title}
                  name="title"
                  onChange={this.onFieldChange}
                  variant="outlined"
                  fullWidth
                  className="form-field"
                />
                <TextField
                  label={_t("Description")}
                  value={description}
                  name="description"
                  onChange={this.onFieldChange}
                  multiline
                  rows={6}
                  fullWidth
                  className="form-field"
                  variant="outlined"
                />
              </div>
              <div className="image-selector-container flex column grow-1">
                <fieldset className="border">
                  {program_id && (
                    // show image selector only if program is saved
                    <>
                      <legend>{_t("Program image")}</legend>
                      <ImageSelector
                        image={Array.isArray(images) ? images[0] : null}
                        {...{ defaultImage }}
                        onSelect={this.onImageSelect}
                        onDelete={this.onImageDelete}
                      />
                    </>
                  )}
                </fieldset>
              </div>
            </div>
            <div className="buttons">
              <Button
                variant="contained"
                color="primary"
                onClick={this.onSave}
                disabled={!programDirty}
              >
                {program_id ? _t("Save") : _t("Add")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (programDirty || coursesDirty) {
                    this.setState({ onConfirmDialog: true });
                  } else {
                    onClose();
                  }
                }}
              >
                {_t("Close")}
              </Button>
            </div>
          </div>
        )}
        {tab === 1 && (
          <div className="form courses">
            <div className="prompt">{_t("programEditPrompt")}</div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <h3>{_t("Available courses")}</h3>
                <SelectList
                  items={coursesNotInProgram}
                  onItemClick={(c) => this.onCourseClick(c, "add")}
                  ItemRenderer={ListItemRenderer}
                  onSearch={this.onSearch}
                  searchProps={{
                    placeholder: _t("Search..."),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h3>{_t("Courses in program")}</h3>
                <SelectList
                  items={courses}
                  onItemClick={(c) => this.onCourseClick(c, "remove")}
                  ItemRenderer={ListItemRenderer}
                  onSearch={this.onSearch}
                  searchProps={{
                    placeholder: _t("Search..."),
                  }}
                />
              </Grid>
            </Grid>
            <div className="buttons">
              <ButtonGroup>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onSaveCourses}
                  disabled={!coursesDirty}
                >
                  {_t("Save courses list")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.resetCoursesToOriginal}
                  disabled={!coursesDirty}
                  onSearch={this.onSearch}
                >
                  {_t("Reset to previous")}
                </Button>
              </ButtonGroup>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (programDirty || coursesDirty) {
                    this.setState({ onConfirmDialog: true });
                  } else {
                    onClose();
                  }
                }}
              >
                {_t("Close")}
              </Button>
            </div>
          </div>
        )}
        <ConfirmDialog
          open={onConfirmDialog}
          cancelText={_t("Cancel")}
          confirmText={_t("Yes")}
          prompt={_t(
            "There are unsaved changes in program, do you really want to close and loose the changes?"
          )}
          onConfirm={onClose}
          onClose={() => this.setState({ onConfirmDialog: false })}
        />
      </div>
    );
  }
}

export default WithTranslations(EditProgram);
