import { Button, InputAdornment, TextField } from "@material-ui/core";
import { GridSearchIcon } from "@material-ui/data-grid";
import React from "react";
import { debounce } from "../../../utils/functions";

class DebounceSearch extends React.Component {
  state = {
    search: "",
  };
  DebounceOnChange = debounce(this.props.onChange);
  componentDidMount() {
    const { search = "" } = this.props;
    this.setState({ search });
  }

  componentDidUpdate(prevProps) {
    const { search } = this.props;
    const { search: prevSearch } = prevProps;
    if (search !== prevSearch) this.setState({ search });
  }

  updateValue = ({ target }) => {
    const { value: search } = target;
    this.setState({ search });
    if (typeof this.DebounceOnChange === "function")
      this.DebounceOnChange(search);
  };

  render() {
    const { placeholder = "search...", onChange, ...other } = this.props;
    const { search } = this.state;
    return (
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <GridSearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <Button
              style={{ opacity: search ? 1 : 0 }}
              onClick={() => this.updateValue({ target: { value: "" } })}
            >
              Clear
            </Button>
          ),
        }}
        name="search"
        value={search}
        placeholder={placeholder}
        onChange={this.updateValue}
        {...other}
      />
    );
  }
}

export default DebounceSearch;
