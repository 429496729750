import { Typography } from '@material-ui/core';
import React from 'react'
import Error from '../../template/Error';
import LocalizedDataGrid from '../../template/localized-Data-Grid';
import Spinner from '../../template/Spinner';

class ProgramsReportItem extends React.Component{

    state = {
        columns: null,
        rows: null,
        error: null
    }

    componentDidMount(){
        this.prepareData();
    }

    componentDidUpdate(prevProps){
        if( prevProps.users !== this.props.users || prevProps.course_list !== this.props.course_list )
            this.prepareData();
    }

    prepareData(){
        let {course_list, users} = this.props;

        if( !Array.isArray(course_list) || !Array.isArray(users) ){
            // TODO: send report to server
            console.debug("wrong data passed to ProgramsReportItem", course_list, users)
            this.setState({error: "No students have started the program so far"})
            return;
        }

        let columns = course_list.map( c => ({
            field: c.course_id,
            headerName: c.course_code,
            disableColumnMenu: true,
            sortable: false,
            type: "number",
            flex: 1
        }))

        // prepare columns
        columns = [
            {field: "id", hide: true},
            // add user field before courses
            {field: "user_name", headerName: "User", flex: 1},
            ...columns,
            // add final grade field after courses
            {field: "final_score", headerName: "Final grade", flex: 1,
                valueFormatter: ({value}) => `${Math.round(value)}%`,
                type: "number"
            }            
        ]

        // prepare rows
        const rows = users.map( u =>{ 
            let {name, last_name,  final_score, progress = [] } = u;
            
            progress = progress || []

            // create an object of total_score values per course, hashed by course id
            const courses = progress.reduce( (res, c) => ({
                ...res,
                // if total score is not set, check if  progress percent is greater than 0, 
                // and if so - set to 0, otherwise set to null (will appear as empty cell in table)
                [c.course_id] : c.total_score ? c.total_score : c.progress_percent > 0 ? 0 : null
            }), {} )

            return {
                id: u.user_id,
                user_name: `${name}${last_name ? ` ${last_name}` : ""}`,
                // insert the courses
                ...courses,
                // insert the final score
                final_score
            }

        } )

        this.setState({columns, rows})
    }


    render(){

        const {columns, rows, error } = this.state;

        if( error ) return <div className="program-report-item">
            <Typography variant="h3">Program: {this.props.title}</Typography>
            <Typography color="secondary">{error}</Typography>
        </div>

        if( !columns || !rows ) return <Spinner />

        return(
            <div className="program-report-item">
                <Typography variant="h3">Program: {this.props.title}</Typography>
                <LocalizedDataGrid
                    autoHeight
                    {...{rows, columns}} 
                />
            </div>
        )
    }
}

export default ProgramsReportItem