import React from "react";
import Spinner from "../../template/Spinner";
import Error from "../../template/Error";
import CustomCardList from "@bit/ses-education.ses-courses-shared-components.template.custom-card-list";
import { Add } from "@material-ui/icons";
import ColumnedList from "./c-list"; //"@bit/ses-education.ses-courses-shared-components.template.custom-card-list/columned-list";
import { Button, ButtonGroup, Typography } from "@material-ui/core";
import EditProgram from "./edit-program";
import ModalWindow from "@bit/ses-education.ses-courses-shared-components.modal-window";
import WithTranslations from "../../WithTranslations";
import ProcessSpinner from "../../template/ProcessSpinner";
import ProgramService from "../../../services/ProgramService";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showMessage } from "../../../redux/notificationActions";
import ConfirmDialog from "../../template/ConfirmDialog";

class ProgramList extends React.Component {
  state = {
    program: null,
    processMessage: null,
    delProgram: null,
  };

  componentDidMount() {
    this.props.fetchTranslations([
      "Programs",
      "Add program",
      "Add Program",
      "Saving program...",
      "Deleting program...",
      "Edit program",
      "Delete program",
      "No file selected",
      "Saving program image...",
      "Program image saved",
      "Deleting program image...",
      "Program image deleted",
      "are you sure you want to delete program",
    ]);
  }

  /**
   * Update program details
   * @param {*} program
   */
  onProgramSave = (program) => {
    const { _t, onProgramsUpdate, message } = this.props;
    // show process message and block UI
    this.setState({ processMessage: _t("Saving program...") }, async () => {
      const programId = await ProgramService.saveProgram(program);
      if (!programId) {
        // show error message
        message(ProgramService.error, "error");

        //unblock UI
        this.setState({ processMessage: null });
        return;
      }
      // TODO: show success message
      message(_t("Program saved"), "success");
      // reload list
      if (typeof onProgramsUpdate === "function") {
        // update programs list
        await onProgramsUpdate();
        // select program from the updated list
        const program = this.selectProgram(parseInt(programId));
        // store program and unblock UI
        this.setState({ program, processMessage: null });
        return;
      }
      // unblock UI
      this.setState({ processMessage: null });
    });
  };

  /**
   * Update course list.
   * @param {*} program_id
   * @param {*} course_ids
   */
  onSaveCourses = (program_id, course_ids) => {
    const { _t, onProgramsUpdate, message } = this.props;
    // show process message and block UI
    this.setState({ processMessage: _t("Saving program...") }, async () => {
      if (!(await ProgramService.updateCourses(program_id, course_ids))) {
        // show error message
        message(ProgramService.error, "error");

        //unblock UI
        this.setState({ processMessage: null });
        return;
      }
      // TODO: show success message
      message(_t("Program saved"), "success");
      // reload list
      if (typeof onProgramsUpdate === "function") {
        // update programs list
        await onProgramsUpdate();
        // select program from the updated list
        const program = this.selectProgram(parseInt(program_id));
        // store program and unblock UI
        this.setState({ program, processMessage: null });
        return;
      }
      // unblock UI
      this.setState({ processMessage: null });
    });
  };

  onSaveImage = async ({ target }) => {
    // TODO: save program image code here
    const { program } = this.state;
    const { program_id } = program;

    const image = target.files && target.files[0] ? target.files[0] : null;

    if (!image) {
      message(_t("No file selected"), "error");
      return false;
    }
    console.debug("onSaveImage program_id", program_id, "image", image);

    // build form data with file
    const data = new FormData();
    data.append("image", image);
    const { _t, onProgramsUpdate, message } = this.props;
    // show process message and block UI
    this.setState(
      { processMessage: _t("Saving program image...") },
      async () => {
        if (!(await ProgramService.updateImage(program_id, data))) {
          // show error message
          message(ProgramService.error, "error");

          //unblock UI
          this.setState({ processMessage: null });
          return;
        }
        // TODO: show success message
        message(_t("Program image saved"), "success");
        // reload list
        if (typeof onProgramsUpdate === "function") {
          // update programs list
          await onProgramsUpdate();
          // select program from the updated list
          const program = this.selectProgram(parseInt(program_id));
          // store program and unblock UI
          this.setState({ program, processMessage: null });
          return;
        }
        // unblock UI
        this.setState({ processMessage: null });
      }
    );
  };

  onDeleteImage = async () => {
    // TODO: save program image code here
    const { program } = this.state;
    const { program_id } = program;
    console.debug("onDeleteImage program_id", program_id);
    const { _t, onProgramsUpdate, message } = this.props;
    // show process message and block UI
    this.setState(
      { processMessage: _t("Deleting program image...") },
      async () => {
        if (!(await ProgramService.deleteImage(program_id))) {
          // show error message
          message(ProgramService.error, "error");

          //unblock UI
          this.setState({ processMessage: null });
          return;
        }
        // TODO: show success message
        message(_t("Program image deleted"), "success");
        // reload list
        if (typeof onProgramsUpdate === "function") {
          // update programs list
          await onProgramsUpdate();
          // select program from the updated list
          const program = this.selectProgram(parseInt(program_id));
          // store program and unblock UI
          this.setState({ program, processMessage: null });
          return;
        }
        // unblock UI
        this.setState({ processMessage: null });
      }
    );
  };

  selectProgram = (program_id) => {
    const { programs } = this.props;

    // failsafe...
    if (!Array.isArray(programs)) return;
    const program = programs.find((p) => p.program_id === program_id);
    return program || {};
  };

  deleteProgram(program_id) {
    const { _t, onProgramsUpdate } = this.props;
    // show process message and block UI
    this.setState({ progressMessage: _t("Deleting program...") }, async () => {
      if (!(await ProgramService.deleteProgram(program_id))) {
        // TODO: show error message
        console.debug(ProgramService.error);
        //unblock UI
        this.setState({ processMessage: null, delProgram: null });
        return;
      }

      // program deleted:

      // TODO: show success message

      // update programs list
      await onProgramsUpdate();

      //unblock UI
      this.setState({ processMessage: null, delProgram: null });
    });
  }

  render() {
    const { programs, error, user, _t } = this.props;
    const { program, processMessage, delProgram } = this.state;
    const organization = Array.isArray(user.organizations)
      ? user.organizations[0]
      : {};
    console.debug("organization", organization);
    return (
      <>
        <Typography variant="h1" color="primary">
          <span>{_t("Programs")}</span>
          <div className="buttons">
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ program: {} })}
            >
              <Add /> {_t("Add Program")}
            </Button>
          </div>
        </Typography>
        {programs === null ? (
          <Spinner />
        ) : programs === false ? (
          <Error error={error} />
        ) : (
          <>
            <CustomCardList
              items={programs.map((p) => ({
                ...p,
                image: Array.isArray(p.images) && p.images[0],
                link: `/programs/${p.program_id}`,
                description: (
                  <div>
                    {
                      // the program was created by this organization - show edit and delete buttons
                      p.created_by_organization &&
                        p.created_by_organization ===
                          organization.organization_id && (
                          <ButtonGroup>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(ev) => {
                                // stop click event propagation
                                ev.preventDefault();
                                this.setState({ program: p });
                              }}
                              size="small"
                            >
                              {_t("Edit program")}
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={(ev) => {
                                // stop click event propagation
                                ev.preventDefault();
                                this.setState({ delProgram: p.program_id })
                              }}
                              size="small"
                            >
                              {_t("Delete program")}
                            </Button>
                          </ButtonGroup>
                        )
                    }
                    <p>{p.description}</p>
                    <ColumnedList items={p.courses || []} />
                  </div>
                ),
              }))}
              itemProps={{ md: 12, lg: 12 }}
              cardImageProps={{ style: { width: "auto", height: "auto" } }}
              // {...{ organization }}
            />
          </>
          // programs.map((p) => (
          //   <div className="program-card">
          //     <Typography variant="h2" color="primary">
          //       {p.title}
          //     </Typography>
          //     <div className="description">{p.description}</div>
          //     {p.courses && (
          //       <div className="course-list-wrapper">
          //         <h3 color="primary">Course list</h3>
          //         <ul className="course-list">
          //           {p.courses.map((c) => (
          //             <li>{c && c.title}</li>
          //           ))}
          //         </ul>
          //       </div>
          //     )}
          //   </div>
          // ))
        )}
        {program && (
          <ModalWindow
            open={true}
            onClose={(ev, reason) => {
              // only close if user clicked on a button, not escape or backdrop click
              if (!["escapeKeyDown", "backdropClick"].includes(reason))
                this.setState({ program: null });
            }}
            className="edit-program-modal"
            formClassName="full-width stretch"
            header={
              program && program.program_id
                ? _t("Edit program")
                : _t("Add program")
            }
          >
            <EditProgram
              {...{ program }}
              onSave={this.onProgramSave}
              onSaveCourses={this.onSaveCourses}
              onSaveImage={this.onSaveImage}
              onDeleteImage={this.onDeleteImage}
              // need this for in-form close buttons
              onClose={() => this.setState({ program: null })}
            />
          </ModalWindow>
        )}
        <ProcessSpinner
          open={Boolean(processMessage)}
          header={processMessage}
        />
        <ConfirmDialog
          open={Boolean(delProgram)}
          prompt={`${_t("are you sure you want to delete program")} ${delProgram}`}
          onConfirm={() => {
            this.deleteProgram(delProgram);
          }}
          onClose={()=> this.setState({delProgram: null})}
        />
      </>
    );
  }
}

ProgramList.propTypes = {
  programs: PropTypes.array,
  error: PropTypes.object,
  fetchTranslations: PropTypes.func,
  _t: PropTypes.func,
  onProgramsUpdate: PropTypes.func,
};

const stateToProps = (state) => ({
  user: state.auth.user,
});
const dispatchToProps = (dispatch) => ({
  message: (message, type) => dispatch(showMessage(message, type)),
});
export default connect(
  stateToProps,
  dispatchToProps
)(WithTranslations(ProgramList));
