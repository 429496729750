import React from "react";
import "./style.scss";
import {
  Button,
  ButtonGroup,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  // Edit,
  Delete,
  // Home,
  Add,
  Archive,
  Visibility,
  Unarchive,
} from "@material-ui/icons";
// import AutoBreadcrumbs from "@bit/ses-education.ses-courses-shared-components.template.auto-breadcrumbs";
import GroupForm from "./group-form";
import ConfirmDialog from "../../template/ConfirmDialog";
import WithTranslations from "../../WithTranslations";
import LocalizedAdminTable from "../../template/localized-admin-table";

const filterFunctions = {
  status: (value) => (row) => {
    return row.group_status === value;
  },
};
class GroupList extends React.Component {
  state = {
    deleteRecord: null,
    archiveRecord: null,
    onConfirm: null,
    confirmationText: null,
    showAddForm: false,
    rows: [],
    filters: { status: "active" },
  };
  componentDidMount = async () => {
    this.onSelect({ target: { value: "active" } }, "status");
    this.props.fetchTranslations([
      "Title",
      "Status",
      "Students",
      "Teachers",
      "Actions",
      "View",
      "Delete",
      "Archive",
      "Activate",
      "Add group",
      "Student groups",
      "Search",
      "Active",
      "Archived",
      "All",
    ]);
  };
  componentDidUpdate = async (prevProps) => {
    if (JSON.stringify(this.props.rows) !== JSON.stringify(prevProps.rows)) {
      this.onSelect({ target: { value: "active" } }, "status");
    }
  };
  deleteRecord = async (deleteRecord) => {
    // const { deleteRecord } = this.state;
    const { onDeleteRecord } = this.props;

    // attempt to delete
    await onDeleteRecord(deleteRecord);
    // when done, close the dialog
    // this.setState({ deleteRecord: null });
    this.closeConirmationDialog();
  };

  archiveRecord = async (archiveRecord) => {
    // const { deleteRecord } = this.state;
    const { onArchiveRecord } = this.props;

    // attempt to delete
    await onArchiveRecord(archiveRecord);
    // when done, close the dialog
    // this.setState({ archiveRecord: null });
    this.closeConirmationDialog();
  };

  unarchiveRecord = async (unarchiveRecord) => {
    // const { deleteRecord } = this.state;
    const { onUnarchiveRecord } = this.props;

    // attempt to delete
    await onUnarchiveRecord(unarchiveRecord);
    // when done, close the dialog
    // this.setState({ archiveRecord: null });
    this.closeConirmationDialog();
  };

  closeConirmationDialog = () =>
    this.setState({ onConfirm: null, confirmationText: null });

  onSelect = (event, type) => {
    let { filters } = this.state;
    filters = { ...filters, [type]: event.target.value };
    console.log("onSelect", filters);
    this.setState({ filters }, this.filterEverything);
  };
  filterEverything = () => {
    console.log("filterEverything", this.state);
    const { filters } = this.state;
    let rows = [...this.props.rows];
    Object.entries(filters).map((entry) => {
      const [key, value] = entry;
      //console.log("filterEverything",filterFunctions[key]);
      if (filterFunctions[key] && value !== -1) {
        rows = rows.filter(filterFunctions[key](value));
      }
    });
    this.setState({ rows });
  };
  render() {
    const { onGroupUpdate = console.debug, isOrganization, _t } = this.props;
    const {
      editId,
      showAddForm,
      deleteRecord,
      archiveRecord,
      onConfirm,
      confirmationText,
      filters,
      rows,
    } = this.state;

    // console.debug("isOrganization", isOrganization, user.credentials);

    const columns = [
      {
        field: "id",
        hide: true,
      },
      {
        field: "title",
        headerName: _t("Title"),
        type: "string",
        flex: 1,
      },
      {
        field: "group_status_title",
        headerName: _t("Status"),
        type: "string",
        flex: 1,
        valueGetter: ({ value }) => {
          // translate status in the table
          return _t(value);
        },
      },
      {
        field: "student_count",
        headerName: _t("Students"),
        type: "number",
        flex: 1,
      },
      {
        field: "teacher_count",
        headerName: _t("Teachers"),
        type: "number",
        flex: 1,
      },
      {
        field: "actions",
        headerName: _t("Actions"),
        flex: 1,
        renderCell: (params) => (
          <div>
            <ButtonGroup>
              <Button
                component={Link}
                to={`/groups/${params.id}`}
                variant="contained"
                color="primary"
                size="small"
                startIcon={<Visibility />}
              >
                {_t("View")}
              </Button>
              {params.row.student_count === 0 && isOrganization && (
                <Button
                  onClick={() =>
                    // this.setState({ confirmDialog: true, delID: params.id })
                    // this.setState({ deleteRecord: { ...params.row } }, () =>
                    //   console.debug(this.state.deleteRecord)
                    // )
                    this.setState({
                      onConfirm: () => this.deleteRecord({ ...params.row }),
                      confirmationText: `Delete group "${
                        params.row.title || "<Unknown>"
                      }"?`,
                    })
                  }
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<Delete />}
                >
                  {_t("Delete")}
                </Button>
              )}
              {params.row.student_count > 0 &&
                isOrganization &&
                params.row.group_status === "active" && (
                  <Button
                    onClick={() =>
                      // this.setState({ confirmDialog: true, delID: params.id })
                      // this.setState({ archiveRecord: { ...params.row } })
                      this.setState({
                        onConfirm: () => this.archiveRecord({ ...params.row }),
                        confirmationText: `Archive group "${
                          params.row.title || "<Unknown>"
                        }"?`,
                      })
                    }
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<Archive />}
                  >
                    {_t("Archive")}
                  </Button>
                )}
              {params.row.student_count > 0 &&
                isOrganization &&
                params.row.group_status === "archived" && (
                  <Button
                    onClick={() =>
                      // this.setState({ confirmDialog: true, delID: params.id })
                      // this.setState({ archiveRecord: { ...params.row } })
                      this.setState({
                        onConfirm: () =>
                          this.unarchiveRecord({ ...params.row }),
                        confirmationText: `Activate group "${
                          params.row.title || "<Unknown>"
                        }"?`,
                      })
                    }
                    variant="contained"
                    className="bg-green color-white"
                    size="small"
                    startIcon={<Unarchive />}
                  >
                    {_t("Activate")}
                  </Button>
                )}
            </ButtonGroup>
          </div>
        ),
      },
    ];
    const buttons = (
      <div className="buttons">
        {isOrganization && (
          <Button
            onClick={() => {
              this.setState({
                editId: null,
                showAddForm: true,
              });
            }}
            variant="contained"
            startIcon={<Add />}
            className="add-button bg-green color-white"
          >
            {_t("Add group")}
          </Button>
        )}
        <div>
          <Select
            // native
            value={filters["status"]}
            onChange={(event) => this.onSelect(event, "status")}
            label="Show:"
          >
            <MenuItem value={"active"}>{_t("Active")}</MenuItem>
            <MenuItem value={"archived"}>{_t("Archived")}</MenuItem>
            <MenuItem value={-1}>{_t("All")}</MenuItem>
          </Select>
        </div>
      </div>
    );
    return (
      <div className="group-list">
        {/* <AutoBreadcrumbs
          items={[{ icon: <Home />, to: "/" }, { text: "Student groups" }]}
        /> */}
        <Typography variant="h1" color="primary">
          {_t("Student groups")}
        </Typography>
        <div className="bubble stretch">
          <LocalizedAdminTable
            autoHeight
            {...{ columns, rows, buttons }}
            searchFields={["title"]}
            searchPlaceholder={_t("Search")}
          />
          <GroupForm
            open={isOrganization && showAddForm}
            onClose={() => this.setState({ showAddForm: false })}
            onSubmit={onGroupUpdate}
          />
          <ConfirmDialog
            open={typeof onConfirm === "function"}
            prompt={confirmationText}
            onConfirm={onConfirm}
            onClose={this.closeConirmationDialog}
          />
          {/* <ConfirmDialog
            open={Boolean(deleteRecord)}
            prompt={`Delete group "${
              deleteRecord ? deleteRecord.title : "-"
            }"?`}
            onConfirm={this.deleteRecord}
            onClose={() => this.setState({ deleteRecord: null })}
          /> */}
          {/* <ConfirmDialog
            open={Boolean(archiveRecord)}
            prompt={`Delete group "${
              archiveRecord ? archiveRecord.title : "-"
            }"?`}
            onConfirm={this.archiveRecord}
            onClose={() => this.setState({ archiveRecord: null })}
          /> */}
        </div>
      </div>
    );
  }
}

export default WithTranslations(GroupList);
