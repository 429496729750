import { TextField, Typography } from "@material-ui/core";
import AsyncButton from "../../template/AsyncButton";
import OrganizationService from "../../../services/OrganizationService";
import React from "react";
import WithTranslations from "../../WithTranslations";

// the organization fields
const fields = [
  "organization_title",
  "url_slug",
 "organization_logo_images",
 "active",
];


class OrganizationProfile extends React.Component {
  state = {
    organization_id: null,
    organization_title: "",
    url_slug: "", 
    organization_logo_images: {},
    active: 1,
  };

  componentDidMount() {
    this.props.fetchTranslations([
      "Organization",
      "title",
      "Organization title",
      "URL slug",
      "Save"
    ]);
    const { currentUser } = this.props;
    const [organization] = currentUser.organizations || [];
    // populate state with pass user organization fields
    const {
      organization_id,
      organization_title = "",
      url_slug = "",
      organization_logo_images = {},
      active = 1,
    } = organization || {} ;

    this.setState({     
      organization_id, 
      organization_title,
      url_slug,
      organization_logo_images,
      active,
    });
  }
  
  // function to update every organization field
  updateValue = ({ target }) => {
    let { name, value, checked } = target;
    if (!fields.includes) {
      console.error("Wrong field name", name);
      return;
    }
    console.debug(name, value)

    // if checkbox, convert to 1/0 for active
    switch(name){
      case "active":
        // convert from true/false to 1/0
        value = checked === true ? 1 : 0;
        break;
    }

    this.setState({ [name]: value });
  };

  // function to update organization in server
  onSave = async () => {
    const { onShowMessage } = this.props;
    const { organization_id, organization_title, url_slug } = this.state;
    const result = await OrganizationService.updateOrganization(organization_id, { organization_title, url_slug });
    if (!result) {
      onShowMessage(OrganizationService.error, "error");
      return false;
    }
    onShowMessage("Organization successfully updated");
  };

  render() {
    const {
      organization_id,
      organization_title,
      organization_logo_images,
      active,
      url_slug,
    } = this.state;
    const {_t} = this.props;
    // geting original data
    const { currentUser } = this.props;
    const [organization] = currentUser.organizations || [];

    const disableSave = 
    // dont allow to save if field empty
      !organization_title || 
      !url_slug || 
      organization_title.length === 0 || 
      url_slug.length === 0 ||
    // dont allow to save if fields didnt change
      (organization_title === organization.organization_title &&
      url_slug === organization.url_slug);

    return (
      <div className="organization-profile">
        <Typography variant="h2" color="primary">
          {_t("Organization")}
        </Typography>
        <div className="bubble">
          <form className="organization-form">
            <div className="fields">
              <TextField
                name="organization_title"
                placeholder={_t("title")}
                label={_t("Organization title")}
                onChange={this.updateValue}
                value={organization_title}
              />
              <TextField
                name="url_slug"
                placeholder={_t("URL slug")}
                label={_t("URL slug")}
                onChange={this.updateValue}
                value={url_slug}
              />
            </div>
            <div className="buttons m-top-10">
              <AsyncButton variant="contained" disabled={disableSave} color="primary" onClick={this.onSave}>{_t("Save")}</AsyncButton>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default WithTranslations(OrganizationProfile);
