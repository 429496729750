import { Button, ButtonGroup, Container, Typography } from '@material-ui/core'
import React from 'react'
// import AutoBreadcrumbs from "@bit/ses-education.ses-courses-shared-components.template.auto-breadcrumbs";
import AutoBreadcrumbs from "../../template/CustomBreadcrumbs";
import { Delete, Home, Add, Person } from '@material-ui/icons';
import UserDetails from '../StudentGroups/user-details';
import GroupForm from '../StudentGroups/group-form';
import ConfirmDialog from '../../template/ConfirmDialog';
import { Link } from "react-router-dom";
import OrganizationService from '../../../services/OrganizationService';
import Group from '../StudentGroups/group';
import Spinner from '../../template/Spinner';
import ProgramService from '../../../services/ProgramService';
import GroupStudent from '../StudentGroups/group-student';
import UserService from '../../../services/UserService';
import AuthService from '../../../services/AuthService';
import WithTranslations from '../../WithTranslations';
import LocalizedDataGrid from '../../template/localized-Data-Grid';
class Teacher extends React.Component{
    state={
        showAddForm: false,
        confirmationText:null,
        onConfirm:null,
        group:null,
        current_user: null,
        programs: null,
        course: null,
    }
    componentDidMount(){
        if(this.props.params.group_id){
            this.fetchGroup();
        }
        if(this.props.params.student_id){
            this.fetchPrograms();
            this.fetchUsers();
        }
        this.props.fetchTranslations([
            "Teacher's groups",
            "Add group",
            "Group title",
            "Actions",
            "View",
            "Remove",
            "Back",
            "Teachers",
            "Are you sure you want to remove this teacher from group?",
        ]);
    }
    componentDidUpdate(prevProps){
        if(prevProps.params.group_id !== this.props.params.group_id &&this.props.params.group_id){
            this.fetchGroup();
        }
        if((prevProps.params.student_id !== this.props.params.student_id &&this.props.params.student_id)||
        (prevProps.params.course_id !== this.props.params.course_id &&this.props.params.course_id)){
            this.fetchUsers();
            this.fetchPrograms();
        }
        if(prevProps._language != this.props._language){
            this.fetchPrograms();   
        }
    }
    fetchGroup = async () => {
        const {onShowMessage} = this.props;
        const groups = await OrganizationService.getStudentGroups();
        const {group_id} = this.props.params;
        if( ! groups || !Array.isArray(groups) ){
            onShowMessage( OrganizationService.error || "Unknown error!", "error" );
            return false;
        }
        else{
            //console.log("fetchGroup",groups);
            let group = groups.find(g => g.student_group_id === parseInt(group_id));
            //console.log("fetchGroup",group);
            this.setState({group});
            return true;
        }
    }
    fetchPrograms = async () => {
        try {
            const {course_id} = this.props.match.params;
            let course = null;
            const programs = await ProgramService.getPrograms();
          if (programs) {
            if(course_id){
                const program = programs.find(p => p.course_ids.includes(parseInt(course_id)));
                if(program){
                    course = program.courses.find(c => c.course_id === parseInt(course_id));
                }
            }
            this.setState({
              programs,
              course,
            },()=>console.log("fetchPrograms",programs,course));
          } else {
            this.setState({ error: "Failed loading programs" });
            return false;
          }
        } catch (err) {
          this.props.onShowMessage(
            `Fetching organization programs from server failed.`,
            "error"
          );
          return false;
        }
      }

    fetchUsers = async () => {
        const users = await UserService.getUsers();
        let current_user = null;
        if (!users) {
            this.props.onShowMessage(
                UserService.error,
                "error"
            );
            return false;
        }
        if(users && this.props.match.params.student_id){
            current_user = users.find(
                (item) => item.id === parseInt(this.props.match.params.student_id)
            );
            this.setState({ current_user },()=>console.log("fetchUser",current_user));
        }
      }
    fetchData = async () => {
        if(this.props.params.group_id){
            this.fetchGroup();
        }
        if(this.props.params.student_id){
            this.fetchPrograms();
            this.fetchUsers();
        }
    }
    onGroupUpdate = async ( data ) => {
        const {onShowMessage} = this.props;

        console.debug("updating group", data);
        // return true;
        if( ! await OrganizationService.updateStudentGroup(data) ){
            onShowMessage( OrganizationService.error || "Unknown error!", "error" );
            return false;
        }
        
        // reload group list
        this.fetchGroup();

        onShowMessage( "Group updated" );
        return true;
    }
    assignUsers  = async( users, type ) => {
        const {group} = this.state;
        const {onShowMessage} = this.props;    

        
        // if no group is stored in state - show error
        if( !group || !group.student_group_id ) {
            onShowMessage( "Wrong group data passed", "error" )
            return false;
        }
        
        let assignFunction, assignText;
        switch(type){
            case "assign-students":
                assignFunction = "assignStudents";
                assignText = "assigning students"
                break;
            case "assign-teachers":
                assignFunction = "assignTeachers";
                assignText = "assigning teachers"
                break;
            case "unassign-students":
                assignFunction = "unassignStudents";
                assignText = "unassigning students"
                break;
            case "unassign-teachers":
                assignFunction = "unassignTeachers";
                assignText = "unassigning teachers"
                break;
            
            default:
                onShowMessage( "Wrong function called", "error" )
                return false;
                break;
        }

        if( typeof OrganizationService[assignFunction] !== "function" ) {
            onShowMessage( "No function called", "error" )
            return false;
        }
        

        if( !await OrganizationService[assignFunction]( group.student_group_id, users) ){
            onShowMessage( OrganizationService.error || `Unknown error ${assignText}`, "error" )
            return false;
        }

        onShowMessage('Operation successfully completed');
        this.fetchGroup();
        return true;
    }

    onAssignStudents = async ( students ) => {
        const result = await this.assignUsers( students, "assign-students");
        if(result){
            this.fetchGroup();
        }
        return result;
    }

    onUnassignStudents = async ( students ) => {
        return this.assignUsers(students, "unassign-students");
    }

    onAssignTeachers = async ( teachers ) => {
        const result = await this.assignUsers( teachers, "assign-teachers");
        if(result){
            this.fetchGroup();
        }
        return result;
    }

    onUnassignTeachers = async ( teachers ) => {
        return this.assignUsers(teachers, "unassign-teachers");
    }

    changeStudentsPassword = async (password) => {
        const { current_user:currentUser } = this.state;
        if (!currentUser)
            return this.props.onShowMessage("No user selected", "error");

        if (!(await AuthService.changeStudentPassword(currentUser.id, password))) {
            return this.props.onShowMessage(AuthService.error, "error");
        }

        this.props.onShowMessage( "Password successfully changed", "success");
        return true;
    }
    
    render(){
        const {id, name, last_name, groups, onGroupUpdate, onunAssignTeachers, onShowMessage, user, _t} = this.props
        //console.log("teacher params",this.props.params)
        const {user_id,group_id,student_id,course_id} = this.props.params;
        const {showAddForm, onConfirm, confirmationText,group,current_user,programs,course} = this.state
        //console.log("teacher render user",user)
        const isOrganization = true;
        const columns = [
            { field: "id", hide: true },      
            { field: "title", headerName: _t("Group title"), flex: 1 },      
            { field: "actions", headerName: _t("Actions"), renderCell : (params) =>(
                <ButtonGroup>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<Person />}
                        to={`/teachers/${user_id}/${params.id}`}
                        component={Link}
                    >
                        {_t("View")}
                    </Button>
                    <Button
                        onClick={() =>(this.setState({
                            confirmationText: _t("Are you sure you want to remove this teacher from group?"),
                            onConfirm: async () => {
                                //console.log("deleting ",id)
                                onunAssignTeachers([id],params.id);
                            }
                        }))}
                        variant="contained"
                        color="secondary"
                        size="small"
                        startIcon={<Delete />}
                    >
                        {_t("Remove")}
                    </Button>
            
                </ButtonGroup>
            ), flex: 1 },      
            
        ]
        let breadcrumbs = [
            { icon: <Home />, to: "/" }, 
            { text: _t("Teachers"), to: "/teachers" },
            { text: `${name} ${last_name}`, to: `/teachers/${user_id}` },
        ];
        let backLink = "/teachers";
        if (group_id && group){
            breadcrumbs = [...breadcrumbs,{text: group.title, to: `/teachers/${user_id}/${group_id}`}]
            backLink = `/teachers/${user_id}`;
        }
        if(student_id && current_user){
            breadcrumbs = [...breadcrumbs,{text: `${current_user.name} ${current_user.last_name}`, to: `/teachers/${user_id}/${group_id}/${student_id}`}]
            backLink = `/teachers/${user_id}/${group_id}`;
        }
        if(course_id && course){
            breadcrumbs = [...breadcrumbs,{text: course.title, to: `/teachers/${user_id}/${group_id}/${student_id}/${course_id}`}]
            backLink = `/teachers/${user_id}/${group_id}/${student_id}`;
        }
        // console.debug("Group rows", rows)
        const onCloseConfirmDialog = () =>
            this.setState({ onConfirm: null, confirmationText: null });
        return(
            <Container>
                <AutoBreadcrumbs
                    items={breadcrumbs}
                    {...{backLink}}
                    backText = {_t("Back")}
                />
                {/* <Typography variant="h1" color="primary">
                Teachers
                </Typography> */}
                    <div className="bubble stretch">
                        {!student_id  && <UserDetails {...this.props }/>}
                        {!group_id &&(<>
                            <Typography variant="h3">{_t("Teacher's groups")}</Typography>
                            <ButtonGroup>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            showAddForm: true,
                                        });
                                    }} 
                                    variant="contained"
                                    startIcon={<Add />}
                                    className="add-button bg-green color-white"
                                >
                                    {_t("Add group")}
                                </Button>
                            </ButtonGroup>
                            <LocalizedDataGrid 
                                rows={groups || [] }
                                columns={columns}
                                autoHeight
                            />
                            <GroupForm
                                open={showAddForm}
                                onClose={() => this.setState({ showAddForm: false })}

                                onSubmit={onGroupUpdate}
                            />
                            <ConfirmDialog
                                open={typeof onConfirm === "function"}
                                onConfirm={() => {
                                    onConfirm();
                                    onCloseConfirmDialog();
                                }}
                                prompt={confirmationText}
                                // clear on close
                                onClose={onCloseConfirmDialog}
                            />
                        </>)}
                        {group_id && !group && <Spinner/>}
                        {group_id && group && !student_id &&(
                            <Group 
                                {... group || {}} 
                                onUpdate={this.onGroupUpdate} 
                                onAssignStudents={this.onAssignStudents} 
                                onUnassignStudents={this.onUnassignStudents} 
                                onAssignTeachers={this.onAssignTeachers} 
                                onUnassignTeachers={this.onUnassignTeachers} 
                                userLink={`/teachers/${user_id}/${group_id}/%student_id%`}
                                id={group_id}
                                user={user}
                                {...{
                                    isOrganization,
                                    onShowMessage
                                }}
                            />
                        )}
                        {student_id && !(current_user && programs ) && <Spinner/>}
                        {student_id && current_user && programs  && (
                            <GroupStudent 
                                {...{...current_user || {}, 
                                        current_user,
                                        programs, 
                                        course_id, 
                                        course, 
                                        courseLink: `/teachers/${user_id}/${group_id}/${student_id}/%course_id%`,
                                        onChange: this.fetchData, 
                                        onPasswordChange: this.changeStudentsPassword 
                                    }
                                } 
                            />
                        )}
                    </div>           
            </Container> 
        )
    }
}

export default WithTranslations(Teacher)